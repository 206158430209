<template>
  <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
    <h2>Twilio Call Inbox</h2>
    <form @submit.prevent="createChannel" class="mx-0 flex flex-wrap">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.channelName.$error }">
          Inbox Name:
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER')"
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR') }}
          </span>
        </label>
      </div>
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumber.$error }">
          Phone Number:
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.PLACEHOLDER')"
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.TWILIO.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
  },
  validations() {
    return {
      channelName: { required },
      phoneNumber: { required, isPhoneE164OrEmpty },
    };
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }

      try {
        const twilioChannel = await this.$store.dispatch(
          'inboxes/createTwilioCallChannel',
          {
            twilio_channel: {
              name: this.channelName,
              type: 'call', // <--- Added type: 'call'
              phone_number: `+${this.phoneNumber.replace(/\D/g, '')}`,
              accountId: this.accountId,
            },
          }
        );
        console.log('this si twilio channle id :::', twilioChannel);
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: twilioChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.TWILIO.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messagingServiceHelptext {
  margin-top: -10px;
  margin-bottom: 15px;

  .checkbox {
    margin: 0px 4px;
  }
}
</style>