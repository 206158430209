/* global axios */
import axios from 'axios';
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }
  async whatsappTemplates(accountId, token){
    return await axios.get(`${this.apiVersion}/accounts/${accountId}/account_whatsapp_templates`,{
      headers: {
        api_access_token: `${token}`,
        'Content-Type': 'application/json',
      },
    })
  }
}

export default new AccountAPI();
