import Vue from 'vue';
import whatsapp_templateAPI from '../../api/whatsapp';

export const SET_WHATSAPP_TEMPLATE_UI_FLAG = 'SET_WHATSAPP_TEMPLATE_UI_FLAG';
export const ADD_TEMPLATES_TO_WHATSAPP = 'ADD_TEMPLATES_TO_WHATSAPP';
export const ADD_TEMPLATE_DATA = 'ADD_TEMPLATE_DATA';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  templatedata: {},
};

export const getters = {
  getTemplates($state) {
    return $state.records;
  },
  getInbox($state) {
    return $state.records;
  },
  createTemplate($state) {
    return $state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getTemplateFlags(_state) {
    return _state.templatedata;
  }
};

export const actions = {
  get: async ({ commit }, { accountId }) => {
    commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isFetching: true });
    try {
      const records = await whatsapp_templateAPI.getInbox({ accountId });
      commit(ADD_TEMPLATES_TO_WHATSAPP, { records, accountId });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },

  getTemp: async ({ commit }, { accountId }) => {
    commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: true });
    try {
      const records = await whatsapp_templateAPI.getTemplates({ accountId });
      commit(ADD_TEMPLATES_TO_WHATSAPP, { records, accountId });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },

  create: async ({ commit }, { accountId, data }) => {
    console.log("Account Id",accountId,data);
    commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: true });
    try {
      const response= await whatsapp_templateAPI.createTemplate({
        accountId,
        data,
      });
      console.log("Template Data:",response);
      const {data: templateData}=response;
      commit(ADD_TEMPLATES_TO_WHATSAPP, { accountId, templateData });
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },
  getInboxData: async ({ commit }, { accountId, InboxId, token }) => {
    try {
      console.log("my InboxID", InboxId);
      console.log("my AccountID", accountId);
      console.log("My token", token);
      const data = await whatsapp_templateAPI.getInbox({ accountId, InboxId, token });
      console.log("response", data);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
  fetchInboxChannelApiKey: async ({ commit }, { inboxId, access_token, accountId }) => {
    try {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: true });
      const response = await whatsapp_templateAPI.fetchInboxchannelApi({ accountId, inboxId, access_token });
      return response;
    } catch (error) {
      console.error('Error fetching inbox channel API keys:', error);
      return null;
    }
    finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },

  fetchTemplates: async ({ commit }, { inbox,keys }) => {
    try {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: true });
      const response = await whatsapp_templateAPI.fetchTemplates({ inbox,keys })
      return response;

    } catch (error) {
      // this.loading = false;
      // this.people = [];
      console.error('Error fetching templates:', error);
    }
    finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },

  ViewForm: async ({ commit }, { id,keys }) => {
    try {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isFetching: true });
      const inboxid = id;
      const response = await whatsapp_templateAPI.ViewTemplates({ inboxid,keys })
      console.log("Response",response);
      commit(ADD_TEMPLATE_DATA, response.data);
      return response.data;
      
    } catch (error) {
    
      console.error('Error Fetching the Data', error);
    } finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
  deleteTemplate: async ({commit},{name,keys,inbox})=>{
    try{
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isDeleting: true });
      const response = await whatsapp_templateAPI.deleteTemplates({name,keys,inbox })
      console.log("Response",response);
      return response;
    }catch(err){
      console.error('Error Fetching the Data', error);
    }finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },
  EditTemplate: async ({commit},{keys,inboxId,dataToSend})=>{
    try{
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isUpdating: true });
      const response = await whatsapp_templateAPI.submitEditData({keys,inboxId,dataToSend })
      return response;
    } catch (err){
      return { success: false, error: err};
    } finally {
      commit(SET_WHATSAPP_TEMPLATE_UI_FLAG, { isUpdating: false });
    }
  }
};
export const mutations = {
  [SET_WHATSAPP_TEMPLATE_UI_FLAG]($state, records) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...records,
    };
  },
  [ADD_TEMPLATES_TO_WHATSAPP]($state, { records, accountId }) {
    Vue.set($state.records, accountId, records);
  },
  [ADD_TEMPLATE_DATA](state, templateData) {
    state.templatedata = templateData; // Update the templatedata object with new data
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
