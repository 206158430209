export default {
    methods: {
        async updateUIAcceptedOutgoingCall() {
            this.seconds = 0;
            this.minutes = 0;
            this.hours = 0;
            setTimeout(() => {
                this.startTimer();
            }, 1000);
        },

        async updateUIDisconnectedOutgoingCall() {
            this.showDragmodal = !this.showDragmodal;
        },

        async makeOutgoingCall() {
            const contact = this.currentContact;
            let account_id = this.web_url.split('accounts/')[1].split('/')[0];
            let inbox_id = contact.contact_inboxes[0].inbox.id;
            var params = {
                to: contact.phone_number,
                account_id: account_id,
                contact_id: contact.id,
                inbox_id: inbox_id,
            };
            if (device) {
                console.log(`Attempting to call ${params.to} ...`);
                call = await device.connect({ params });
                this.seconds = 0;
                this.minutes = 0;
                this.hours = 0;
                call.on('accept', this.updateUIAcceptedOutgoingCall);
                call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
                call.on('cancel', this.updateUIDisconnectedOutgoingCall);
            } else {
                console.log('Unable to make call.');
            }
        },

        async CallHangup() {
            console.log('Call Disconnected');
            this.updateFormattedTime();
            call.disconnect();
            this.updateUIDisconnectedOutgoingCall();
            return;
        },

        async getAudioDevices() {
            console.log('Inside getAudioDevices::::::::::::::::::::');
            await navigator.mediaDevices.getUserMedia({ audio: true });
        },

        addDeviceListeners(device) {
            device.on('registered', function () {
                console.log('Twilio.Device Ready to make and receive calls!');
            });
            device.on('error', function (error) {
                console.log('Twilio.Device Error: ' + error.message);
            });
        },

        async intitializeDevice() {
            console.log('Initializing device');
            device = new Twilio.Device(token, {
                logLevel: 1,
            });
            this.addDeviceListeners(device);
            device.register();
        },

        async startupClient() {
            console.log('Requesting Access Token...');
            const contact = this.currentContact;
            // const chat = this.currentChat;
            // Now you can use the contact data in your method
            try {
                this.showDragmodal = !this.showDragmodal;
                this.updateFormattedTime();
                this.getAudioDevices();
                console.log('Contact in yourMethod:', contact);
                const data = await axios.get(`${this.base_url}/twilio/token`, {
                    params: {
                        to: contact.phone_number,
                    },
                });
                console.log('Twilio Token:', data);
                token = data.data.token;
                console.log('got token' + token);
                await this.intitializeDevice();
                this.makeOutgoingCall();
            } catch (err) {
                console.log(err);
                console.log(
                    'An error occurred. See your browser console for more information.'
                );
            }
        },
        startTimer() {
            this.timerRunning = true;
            this.timer = setInterval(this.updateTimer, 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
            this.timerRunning = false;
        },
        updateTimer() {
            this.seconds++;
            if (this.seconds === 60) {
                this.seconds = 0;
                this.minutes++;
                if (this.minutes === 60) {
                    this.minutes = 0;
                    this.hours++;
                }
            }
        },
        pad(value) {
            return value < 10 ? '0' + value : value;
        },
        closemodal() {
            this.showDragmodal = false;
        },
        updateFormattedTime() {
            // Your logic to update formattedTime
            this.$nextTick(() => {
                // Using $nextTick to ensure Vue has updated the DOM before retrieving the formattedTime
                console.log('Formatted time updated:', this.formattedTime);
            });
        },
    }
}
