<template>
  <div v-if="show" class="main-dialpad-modal">
    <div class="dialpad-modal">
      <div>
        <div class="selected-options">
          <div
            class="select-contacts"
            @click="showContact"
            :class="{ selected: showContacts }"
          >
            {{ $t('CONVERSATION.REPLYBOX.CONTACTS') }}
          </div>
          <div
            class="select-dialer"
            @click="showDialling"
            :class="{ selected: showDialer }"
          >
            {{ $t('CONVERSATION.REPLYBOX.DIALPAD') }}
          </div>
        </div>
        <div v-if="showContacts">
          <div class="searching-box">
            <input
              type="search"
              id="gsearch"
              v-model="searchQuery"
              name="gsearch"
              :placeholder="$t('CONVERSATION.REPLYBOX.SEARCH_TEXT')"
              class="search-input"
            />
            <draggable-file
              class="dialpadModal"
              :show="showDragmodal"
              :formatted="formattedTime"
              :hangup="CallHangup"
              :selectedContact="selectedContact"
            />
          </div>
          <div class="search-inbox">
            <table>
              <tbody>
                <tr v-if="filteredContacts.length === 0">
                  <td colspan="3">{{ $t('CONVERSATION.REPLYBOX.FETCHING_CONTACTS') }}</td>
                </tr>
                <tr
                  v-for="contact in filteredContacts"
                  :key="contact.id"
                  class="contact-person"
                >
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.phone_number }}</td>
                  <td @click="startupClient(contact)">
                    <fluent-icon
                      icon="call-dial"
                      label="call"
                      title="call"
                      size="30"
                      class="call-button"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="main-dialer-box">
          <dialer-pad v-if="showDialer" :result="response"></dialer-pad>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableFile from '../../widgets/WootWriter/Draggable.vue';
import DialerPad from './ContactDailerpad';
import { mapGetters } from 'vuex';
let token;
let device;
let call;
export default {
  components: { DraggableFile, DialerPad },
  data() {
    return {
      digitsText: '',
      searchQuery: '',
      digits: [], // Array to store entered digits
      showDragmodal: false,
      timerRunning: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
      selectedContact: null,
      response: [],
      showDialer: false,
      showContacts: true,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      baseUrl: 'getbaseUrl',
    }),
    formattedTime() {
      console.log('this.pad', this.pad);
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
    filteredContacts() {
      const query = this.searchQuery.toLowerCase();
      return this.result.filter(
        contact =>
          contact.name.toLowerCase().includes(query) ||
          contact.phone_number.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    async updateUIAcceptedOutgoingCall() {
      this.startTimer();
    },

    async updateUIDisconnectedOutgoingCall() {
      this.showDragmodal = false;
      this.resetTimer();
    },

    async makeOutgoingCall() {
      console.log('URL:::::', window.location.href);
      var params = {
        To: this.selectedContact.phone_number,
        account_id: this.accountId,
        contact_id: this.selectedContact.id,
      }

      if (this.selectedContact.contact_inboxes[0]) {
        params.inbox_id = this.selectedContact.contact_inboxes[0].inbox.id;
      }

      if (device) {
        console.log(`Attempting to call ${params.To} ...`);
        call = await device.connect({ params });
        call.on('accept', this.updateUIAcceptedOutgoingCall);
        call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
        call.on('cancel', this.updateUIDisconnectedOutgoingCall);
      } else {
        console.log('Unable to make call.');
      }
    },

    async CallHangup() {
      console.log('Call Disconnected');
      call.disconnect();
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
    },

    async getAudioDevices() {
      console.log('Inside getAudioDevices::::::::::::::::::::');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },

    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
    },

    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
      });
      this.addDeviceListeners(device);
      device.register();
    },

    async startupClient(contact) {
      console.log('Requesting Access Token...', contact);
      this.selectedContact = contact;
      try {
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
        this.showDragmodal = !this.showDragmodal;
        this.selectedContact = contact;
        console.log('Selected COntact', this.selectedContact);
        this.getAudioDevices();
        const data = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            To: contact.phone_number,
            account_id: this.accountId
          },
        });
        console.log('Got a token::::::::::::::::', data);
        token = data.data.token;
        console.log(`${token}`);
        await this.intitializeDevice();
        this.makeOutgoingCall();
      } catch (err) {
        console.log(err);
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },

    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    resetTimer() {
      this.stopTimer();
      this.selectedContact = null;
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    async showDialling() {
      try {
        // You can add any other logic here if needed
        console.log('ShowDialling');
        this.showDialer = true;
        this.showContacts = false;
        // Dispatch the action and wait for the response
        this.response = await this.$store.dispatch('contacts/dialpadContacts');
        console.log('this.response', this.response);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    },
    showContact() {
      this.showDialer = false;
      this.showContacts = true;
    },
  },
};
</script>

<style scoped>
/* Add your styling here */

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.digit,
.dig {
  float: left;
  padding: 10px 30px;
  font-size: 2rem;
  cursor: pointer;
}

.sub {
  font-size: 0.8rem;
  color: grey;
}

.container {
  background-color: white;
  border-radius: 5%;
  width: 320px;
  /* padding: 20px; */
  margin: 30px auto;
  height: 500px;
  text-align: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#output {
  font-family: 'Exo';
  font-size: 2rem;
  height: 60px;
  font-weight: bold;
  color: #1976d2;
}

.botrow {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.digit:active,
.dig:active {
  background-color: #e6e6e6;
}

.dig {
  float: left;
  padding: 10px 20px;
  margin: 10px;
  width: 30px;
  cursor: pointer;
}
#call {
  padding: 15px;
  border-radius: 50%;
  background-color: green;
  cursor: pointer;
}

body.dark .main-dialpad-modal {
  position: absolute;
  left: 73px;
  top: 180px;
  z-index: 12;
  width: 25%;
  height: 588px;
}
body:not(.dark) .main-dialpad-modal {
  position: absolute;
  left: 73px;
  top: 180px;
  z-index: 12;
  height: 588px;
  width: 25%;
}
body.dark .dialpad-modal {
  background: #151718;
}

body:not(.dark) .dialpad-modal {
  background: #fcfafa;
}

.dialpad-modal >>> .modal-container > button {
  display: none;
}
.modal-mask {
  justify-content: start !important;
  margin-left: 75px;
  margin-top: 50px;
  background: transparent;
  z-index: 499;
}

.dialpadModal.modal-mask {
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.9);
  margin-top: 0px;
}

.dialpadModal >>> .modal-container {
  margin-top: 250px;
}
.search-inbox {
  height: 431px;
  overflow: auto;
  padding: 10px 20px;
}
.button.clear.secondary {
  display: none !important;
}
#output input {
  background: #fff;
  border: 2px solid #fff !important;
}

.search-input {
  margin: 0px 20px;
  /* position: fixed; */
  width: 540px;
  z-index: 9;
  background-color: #fff;
}
.contact-person {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}
.contact-person:hover {
  transform: scale(1.001);
}
.call-button {
  color: green;
}
.searching-box {
  margin-top: 15px;
  width: 100%;
}
.searching-box input {
  width: 90%;
  background-color: transparent;
  margin-top: 15px;
}

body.dark .selected-options {
  background-color: #000;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0px;
}
body:not(.dark) .selected-options {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0px;
}
.select-contacts,
.select-dialer {
  width: 50%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected {
  background-color: #1f93ff;
  color: #fff;
  padding: 5px 0px;
  border-radius: 10px;
}
</style>
