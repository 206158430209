<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="pageTitle" />
    <form class="mx-0 flex flex-wrap" @submit.prevent="editLabel">
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="w-full label-name--input"
        :label="$t('LABEL_MGMT.FORM.NAME.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        @input="$v.title.$touch"
      />
      <woot-input
        v-model.trim="description"
        :class="{ error: $v.description.$error }"
        class="w-full"
        :label="$t('LABEL_MGMT.FORM.DESCRIPTION.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        @input="$v.description.$touch"
      />

      <div class="demo-modal w-full" v-if="forAutomation">
        <div class="w-full">
           <div
            class="demo-data"
            v-for="(data, index) in selectedResponse.label_triggers"
            :key="index"
          >
            <woot-input
              v-model.trim="data.trigger_before"
              :class="{ error: $v.triggeredBefore.$error }"
              class="triggeredBefore"
              :label="$t('LABEL_MGMT.FORM.TRIGGEREDBEFORE.LABEL')"
              :placeholder="$t('LABEL_MGMT.FORM.TRIGGEREDBEFORE.PLACEHOLDER')"
              data-testid="label-triggeredBefore"
              @input="$v.triggeredBefore.$touch"
            />

            <div class="macro">
              <label :class="{ error: $v.macro.$error }">
                {{ $t('LABEL_MGMT.FORM.MACRO.LABEL') }}
                <select v-model="data.macro.id">
                  <option
                    v-for="macro in macros"
                    :key="macro.id"
                    :value="macro.id"
                  >
                    {{ macro.name }}
                  </option>
                </select>
              </label>
            </div>

            <div class="macro">
              <label :class="{ error: $v.macro.$error }">
                {{ $t('LABEL_MGMT.FORM.AUTOMATION.LABEL') }}
                <select v-model="data.is_before">
                  <option :value="true">
                    {{ $t('LABEL_MGMT.FORM.AUTOMATION.TRUE') }}
                  </option>
                  <option :value="false">
                    {{ $t('LABEL_MGMT.FORM.AUTOMATION.FALSE') }}
                  </option>
                </select>
              </label>
            </div>

            <div class="delete-button">
              <woot-button
                v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
                icon="delete"
                variant="smooth"
                size="small"
                color-scheme="alert"
                @click.prevent="removeData(index, data.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="add-button" @click="addData" v-if="forAutomation">
        {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.ADDLABEL') }}
      </div>

      <div class="w-full">
        <label>
          {{ $t('LABEL_MGMT.FORM.COLOR.LABEL') }}
          <woot-color-picker v-model="color" />
        </label>
      </div>
      <div class="w-full">
        <input v-model="showOnSidebar" type="checkbox" :value="true" />
        <label for="conversation_creation">
          {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL') }}
        </label>
      </div>
      <div class="w-full">
        <input v-model="forAutomation" type="checkbox" :value="true" />
        <label for="conversation_creation"
          >{{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.AUTOMATION') }}
        </label>
      </div>
      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="$v.title.$invalid || uiFlags.isUpdating"
          :is-loading="uiFlags.isUpdating"
        >
          {{ $t('LABEL_MGMT.FORM.EDIT') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('LABEL_MGMT.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import validations from './validations';

export default {
  mixins: [alertMixin, validationMixin],
  props: {
    selectedResponse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      color: '#000',
      description: '',
      dataList: [
        {
          macro_id: '',
          trigger_before: '',
        },
      ],
      title: '',
      showOnSidebar: true,
      forAutomation: false,
      selectedValue: false,
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
      macros: ['macros/getMacros'],
    }),
    pageTitle() {
      return `${this.$t('LABEL_MGMT.EDIT.TITLE')} - ${
        this.selectedResponse.title
      }`;
    },
  },

  mounted() {
    this.$store.dispatch('macros/get');
    this.setFormValues();
    if (this.selectedResponse.label_triggers.length > 0) {
      this.forAutomation = true;
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setFormValues() {
      this.title = this.selectedResponse.title;
      this.description = this.selectedResponse.description;
      this.showOnSidebar = this.selectedResponse.show_on_sidebar;
      this.color = this.selectedResponse.color;
    },

    editLabel() {
      const labelTriggersAttributes = this.selectedResponse.label_triggers.map(
        trigger => ({
          id: trigger?.id,
          trigger_before: trigger.trigger_before,
          macro_id: trigger.macro.id, // Use macro_id directly
          is_before: trigger.is_before,
        })
      );
      
      if (this.forAutomation) {
        if (!this.validateMacroIds(labelTriggersAttributes)) {
          this.showAlert('Macro ID cannot be null.');
          return;
        }
        this.$store
          .dispatch('labels/update', {
            id: this.selectedResponse.id,
            color: this.color,
            description: this.description,
            title: this.title.toLowerCase(),
            show_on_sidebar: this.showOnSidebar,
            automation: this.forAutomation,
            label_triggers_attributes: labelTriggersAttributes,
            selected_value: this.selectedValue,
          })
          .then(() => {
            this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'));
            setTimeout(() => this.onClose(), 10);
          })
          .catch(() => {
            this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'));
          });
      } else{
        this.$store
          .dispatch('labels/update', {
            automation: this.forAutomation,
          })
          .then(() => {
            this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'));
            setTimeout(() => this.onClose(), 10);
          })
          .catch(() => {
            this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'));
          });
      }
    },

    addData() {
      
      if (this.selectedResponse && this.selectedResponse.label_triggers) {
        this.selectedResponse.label_triggers.push({
          trigger_before: '', // Make sure this key matches what your API returns
          macro: { id: null }, // Macro should be an object with an id
          is_before: false, // Make sure this key matches what your API returns
        });
      } else {
        console.error('selectedResponse or label_triggers is not defined');
      }
    },
    
    async removeData(index, id) {
      try {
        if (
          this.selectedResponse &&
          this.selectedResponse.label_triggers &&
          id
        ) {
          const data = await this.$store.dispatch('labels/deleteLabelTrigger', {
            labelId: this.selectedResponse.id,
            triggerId: id,
          });
          if (data.status == 200) {
            this.showAlert('Label Triggered Deleted Successfully');
            this.selectedResponse.label_triggers.splice(index, 1);
            return;
          } else if (data.status != 200) {
            this.showAlert('Label Triggered Not Deleted at Yet.');
          }
          return;
        } else if (id == null) {
          this.selectedResponse.label_triggers.splice(index, 1);
          return;
        }
      } catch (err) {
        console.error('Data Deleting Error');
        this.showAlert('An Error Occured during Deletion');
      }
    },
    validateMacroIds(labelTriggers) {
      return labelTriggers.every(trigger => trigger.macro_id !== null);
    },
  },
};
</script>
<style lang="scss" scoped>
// Label API supports only lowercase letters
.label-name--input {
  ::v-deep {
    input {
      @apply lowercase;
    }
  }
}
.demo-modal {
  display: flex;
  justify-content: space-between;
}
.demo-data {
  // width: 80%;
  display: flex;
  justify-content: space-between;
}
.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  font-size: 10px;
  background: aliceblue;
  font-weight: 500;
  border-radius: 5px;
}
.macro {
  width: 25%;
}
.triggeredBefore {
  width: 33%;
}
</style>
