import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
      getLabels: 'labels/labelsdata'
    }),
    savedLabels() {
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
    activeLabels() {
      const allFalse = this.getLabels.filter(({ title }) =>this.savedLabels.includes(title)).every(label => !label.automation);
      if(this.getLabels.filter(({ title }) =>
        this.savedLabels.includes(title)).length==0||allFalse ){
        localStorage.setItem('firstAutomationLabelAdded',false);
      }
      else if(this.getLabels.filter(({ title }) =>
        this.savedLabels.includes(title)).length>0){
          localStorage.setItem('firstAutomationLabelAdded',true);
      }
      return this.getLabels.filter(({ title }) =>
        this.savedLabels.includes(title)
      );
    },

    inactiveLabels() {
      return this.accountLabels.filter(
        ({ title }) => !this.savedLabels.includes(title)
      );
    },
  },
  mounted() {
    this.$store.dispatch('labels/getlabelsindex');
  },
  methods: {
    addLabelToConversation(label) {
      if (label.automation) {
        const firstAutomationLabelAdded=localStorage.getItem('firstAutomationLabelAdded');
        if (firstAutomationLabelAdded=='false') {
          localStorage.setItem('firstAutomationLabelAdded',true);
          this.savedLabels.push(label.title);
          const result = this.activeLabels.map(item => item.title);
          result.push(label.title);
          this.onUpdateLabels(result);
        } else {
          // For subsequent automation labels, set pendingLabel and show the modal for confirmation
          this.pendingLabel = label;
          this.showModal = true;
        }
      } else {
        // If the label doesn't have automation enabled, add it directly
        this.savedLabels.push(label.title);
        const result = this.activeLabels.map(item => item.title);
        result.push(label.title);
        this.onUpdateLabels(result);
      }
    },
    removeLabelFromConversation(value) {
      const result = this.activeLabels
        .map(label => label.title)
        .filter(label => label !== value);
      this.onUpdateLabels(result);
    },
    async onUpdateLabels(selectedLabels) {
      this.$store.dispatch('conversationLabels/update', {
        conversationId: this.conversationId,
        labels: selectedLabels,
      });
    },
    closeModal() {
      this.modalclosing = true;
      this.showModal = false;
    },

    confirmYes() {
      if (this.pendingLabel) {
        this.savedLabels.push(this.pendingLabel.title); // Add the pending label to savedLabels
        const result = this.activeLabels.map(item => item.title);
        result.push(this.pendingLabel.title);
        this.onUpdateLabels(result);
        this.pendingLabel = null; // Clear the pending label
      }
      this.showModal = false;
    },

    confirmNo() {
      this.pendingLabel = null; // Clear the pending label
      this.showModal = false;
    },
  },
};
