<template>
    <div class="dialer-pad">
      <div class="mb-4">
        <input
          type="text"
          v-model="phoneNumber"
          class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
        />
      </div>
      <div class="dialer-button">
        <div class="grid grid-cols-3 gap-2">
          <template v-for="buttonRow in rows">
            <button
              v-for="button in buttonRow"
              :key="button"
              @click="addToPhoneNumber(button)"
              class="cursor-pointer calling-button rounded-full"
            >
              {{ button }}
            </button>
          </template>
        </div>
        <woot-button
          class="call-button"
          icon="call-start"
          style="background-color: #44ce4b"
          @click="startupClient"
        >
          {{ $t('CONVERSATION.REPLYBOX.CALL') }}
        </woot-button>
      </div>
      <draggable-file
        :show="showDragmodal"
        :formatted="formattedTime"
        :hangup="CallHangup"
        :selectedContact="selectedContact"
      />
    </div>
  </template>
  
  <script>
  // let token;
  let device;
  let call;
  import DraggableFile from '../../widgets/WootWriter/Draggable.vue';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      DraggableFile,
    },
    props: {
      result: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        phoneNumber: '',
        showDragmodal: false,
        selectedContact: null,
        seconds: 0,
        minutes: 0,
        hours: 0,
        token: '',
        contact: {},
        rows: [
          ['1', '2', '3'],
          ['4', '5', '6'],
          ['7', '8', '9'],
          ['+', '0', '#'],
        ],
      };
    },
    computed: {
      ...mapGetters({
        accountId: 'getCurrentAccountId',
        baseUrl: 'getbaseUrl',
      }),
      formattedTime() {
        return (
          this.pad(this.hours) +
          ':' +
          this.pad(this.minutes) +
          ':' +
          this.pad(this.seconds)
        );
      },
    },
    watch: {
      phoneNumber(newValue) {
        // Filter the result array based on the new phone number
        console.log('Main Result APi', this.result);
        const filteredResult = this.result.filter(contact =>
          contact.phone_number.includes(newValue)
        );
        this.contact = filteredResult;
        // Log or use the current state of the filtered result
      },
    },
    methods: {
      addToPhoneNumber(digit) {
        console.log(digit);
        this.phoneNumber += digit;
        this.phoneNumber = this.phoneNumber.replace(/[^\+0-9#]/g, '');
        console.log('Phone Number:-', this.phoneNumber);
      },
  
      async updateUIAcceptedOutgoingCall() {
        this.startTimer();
      },
  
      async updateUIDisconnectedOutgoingCall() {
        this.showDragmodal = false;
        this.resetTimer()
      },
  
      async makeOutgoingCall() {
        var params = {
          To: this.contact.phone_number,
          account_id: this.accountId,
          contact_id: this.contact.id,
        };
        if (this.contact.contact_inboxes[0]) {
          params.inbox_id = this.contact.contact_inboxes[0].inbox_id;
        }
        if (device) {
          console.log(`Attempting to call ${params.To} ...`);
          call = await device.connect({ params });
          call.on('accept', this.updateUIAcceptedOutgoingCall);
          call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
          call.on('cancel', this.updateUIDisconnectedOutgoingCall);
        } else {
          console.log('Unable to make call.');
        }
      },
  
      async CallHangup() {
        console.log('Call Disconnected');
        call.disconnect();
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
      },
  
      async getAudioDevices() {
        console.log('Inside getAudioDevices::::::::::::::::::::');
        await navigator.mediaDevices.getUserMedia({ audio: true });
      },
  
      addDeviceListeners(device) {
        device.on('registered', function () {
          console.log('Twilio.Device Ready to make and receive calls!');
        });
        device.on('error', function (error) {
          console.log('Twilio.Device Error: ' + error.message);
        });
      },
  
      async intitializeDevice() {
        console.log('Initializing device');
        device = new Twilio.Device(this.token, {
          logLevel: 1,
        });
        this.addDeviceListeners(device);
        device.register();
      },
  
      async startupClient() {
        if (this.phoneNumber.length > 0) {
          try {
            this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
            this.showDragmodal = !this.showDragmodal;
            this.getAudioDevices();
            console.log('Requesting Access Token...');
            const data = await axios.get(`${this.baseUrl}/twilio/token`, {
              params: {
                To: this.phoneNumber,
                account_id: this.accountId,
              },
            });
            this.token = data.data.token;
            this.contact = data.data.contact.contact;
            this.contact.contact_inboxes = [];
            this.contact.contact_inboxes.push(data.data.contact.contact_inboxes);
            this.selectedContact = {
              name: this.contact.name,
              phone_number: this.contact.phone_number,
            };
            console.log('Contact:::::::::::', this.contact);
            await this.intitializeDevice();
            this.makeOutgoingCall();
          } catch (err) {
            console.log(err);
          }
        }
      },
      startTimer() {
        this.timerRunning = true;
        this.timer = setInterval(this.updateTimer, 1000);
      },
      stopTimer() {
        clearInterval(this.timer);
        this.timerRunning = false;
      },
      updateTimer() {
        this.seconds++;
        if (this.seconds === 60) {
          this.seconds = 0;
          this.minutes++;
          if (this.minutes === 60) {
            this.minutes = 0;
            this.hours++;
          }
        }
      },
      pad(value) {
        return value < 10 ? '0' + value : value;
      },
      resetTimer() {
        this.stopTimer();
        this.seconds = 0;
        this.minutes = 0;
        this.hours = 0;
      },
    },
  };
  </script>
  <style scoped>
  .dialer {
    position: absolute;
    top: 80px;
    left: 65px;
    z-index: 9;
    background-color: black;
    font-size: 20px;
  }
  
  .dialer-pad input {
    font-size: 20px;
    border: 2px solid lightgrey;
    text-align: right;
  }
  
  body.dark .dialer-pad {
    background-color: #232323;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  /* Apply background color for default mode */
  body:not(.dark) .dialer-pad {
    /* background-color: lightskyblue; */
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  body.dark .calling-button {
    background: #2d2d2d;
    place-items: center;
    color: #fff;
    padding: 5px;
    width: 50px;
    height: 50px;
  }
  
  body:not(.dark) .calling-button {
    background: #e3e3e3;
    place-items: center;
    padding: 5px;
    width: 50px;
    height: 50px;
  }
  
  .call-button {
    color: #fff;
    padding: 10px;
    border-radius: 10%;
    margin: 17px;
  }
  
  .dialling-button {
    background: #272525;
    border-radius: 5%;
  }
  .dialer-button {
    text-align: center;
  }
  
  .dialer-pad {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 10px;
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    place-items: center;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  .gap-2 {
    gap: 1rem;
  }
  </style>
  