<template>
    <div class="image-container image message-text__wrap">
      <div v-for="(attachment, index) in contact.contact_attachments" :key="index" class="image-item custom-tooltip">
        <template v-if="attachment.content_type.split('/')[0] === 'image'">
          <img :src="attachment.url" @click="onClick" alt="Image" class="image">
          <span class="custom-tooltiptext">image</span>
          <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
            <img :src="attachment.url" class="modal-image skip-context-menu" />
          </woot-modal>
        </template>
        <template v-else-if="attachment.content_type.split('/')[0] === 'application'">
          <img src="~dashboard/assets/images/doc.png" @click="openLink(attachment.url)" :alt="attachment.content_type" class="image">
          <span class="custom-tooltiptext">docs</span>
        </template>
        <template v-else-if="attachment.content_type.split('/')[0] === 'audio'">
          <img src="~dashboard/assets/images/audio.png" @click="openLink(attachment.url)" :alt="attachment.content_type" class="image">
          <span class="custom-tooltiptext">audio</span>
        </template>
        <template v-else-if="attachment.content_type.split('/')[0] === 'video'">
          <video :src="attachment.url" muted playsInline @click="onVideoClick" class="image" />
          <span class="custom-tooltiptext">video</span>
          <woot-modal :show.sync="videoshow" :on-close="onVideoClose">
            <video
              :src="attachment.url"
              controls
              playsInline
              class="modal-video skip-context-menu"
            />
          </woot-modal>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import Spinner from 'shared/components/Spinner';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      Spinner,
    },
    props: {
      getImageUrl: '',
      contactId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        show: false,
        videoshow: false,
      };
    },
    methods: {
      onVideoClose() {
        this.videoshow = false;
      },
      onClose() {
        this.show = false;
      },
      onClick() {
        this.show = true;
      },
      onImgError() {
        this.$emit('error');
      },
      openLink(url) {
        const win = window.open(url, '_blank', 'noopener');
        if (win) win.focus();
      },
      onVideoClick() {
        this.videoshow = true;
      },
    },
    computed: {
      ...mapGetters({
        uiFlags: 'contacts/getUIFlags',
      }),
      contact() {
        return this.$store.getters['contacts/getContact'](this.contactId);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .image-container {
    display: flex;
    flex-wrap: wrap;
  }
  .image-item {
    width: 33.33%;
    padding: 5px;
    box-sizing: border-box;
    min-height: 10px;
    min-width: 10px;
  }
  .image {
    width: 100%;
    height: 80px;
  }
  .custom-tooltip{
    position: relative;
    display: inline-block;
  }
  .custom-tooltip .custom-tooltiptext {
    visibility: hidden;
    width: 63px;
    background-color: #3989F8;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 2;
    top: 115%;
    left: 50%;
    right: 0;
    transform: translate(-50%, 0%);
    margin-left: -60px; 
    opacity: 0;
    white-space: nowrap; 
    transition: opacity 0.3s;
    margin: auto;
    word-break: break-word;
    white-space: nowrap;
  }
  .custom-tooltip:hover::after {
    display: unset;
  }
  .custom-tooltip::after {
    content: "";
    position: absolute;
    bottom: -15%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3989F8 transparent transparent transparent;
    transform: rotate(180deg);
    display: none;
  }
  .custom-tooltip:hover .custom-tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  </style>
  