<template>
  <div
    class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] overflow-y-auto"
  >
    <page-header
      :header-title="$t('WHATSAPP_TEMPLATES.CREATE_FLOW.CREATE.TITLE')"
      :header-content="$t('WHATSAPP_TEMPLATES.CREATE_FLOW.CREATE.DESC')"
    />
    <div class="flex flex-wrap">
      <whatsapp-form
        :on-submit="createWhatsapp"
        :submit-in-progress="false"
        :submit-button-text="$t('WHATSAPP_TEMPLATES.FORM.SUBMIT_CREATE')"
      />
      <!-- <whatsapp-form
        :inbox-lists="this.getInbox[2]"
        :on-submit="createWhatsapp"
        :submit-in-progress="false"
        :submit-button-text="$t('WHATSAPP_TEMPLATES.FORM.SUBMIT_CREATE')"
      /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WhatsappForm from '../WhatsappForm';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WhatsappForm,
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      enabledFeatures: {},
      inboxLists: [],
    };
  },
  mounted() {
    
    this.$store.dispatch('whatsappTemplates/get', {
      accountId: this.currentAccountId,
    });
  },
  computed: {
    ...mapGetters({
      // getInbox: 'whatsappTemplates/getInbox',
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    createWhatsapp(template_data) {
      router.replace({
        name: 'whatsapp_template_add_contents',
        params: {
          page: 'add_contents',
          template_data: template_data,
        },
      });
    },
  },
};
</script>
