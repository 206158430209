<template>
    <div
      class="wizard-body w-[100%] flex-shrink-0 flex-grow-0 max-w-[100%] overflow-y-auto custom-height"
    >
      <div class="w-full">
        <page-header :header-content="$t('WHATSAPP_TEMPLATES.ADD.DESC')" />
      </div>
  
      <div class="w-full">
        <whatsapp-edit-template
          :submit-in-progress="false"
          :submit-button-text="$t('WHATSAPP_TEMPLATES.ADD.EDIT_TEXT')"
        />
      </div>
      <!-- <div>
        <wizard/>
      </div> -->
    </div>
  </template>
  
  <script>
  import alertMixin from 'shared/mixins/alertMixin';
  // import router from '../../../../index';
  import PageHeader from '../../SettingsSubPageHeader';
  import WhatsappEditTemplate from '../WhatsappEditTemplate.vue';
//   import WhatsappSelector from '../WhatsappEditTemplate';

  // import Wizard from '../../../../../components/ui/Wizard.vue';
  
  export default {
    components: {
      PageHeader,
      WhatsappEditTemplate,
      // Wizard
    },
    mixins: [alertMixin],
  
    data() {
      return {
        enabledFeatures: {},
      };
    },
    props: {
      // inbox: {
      //   type: Object,
      //   required: true
      // },
      // currentAccountId: {
      //   type: String,
      //   required: true,
      // },
    },
    mounted() {
      this.template_data = this.$route.params.template_data;
    },
    methods: {
    //   takeLook(content_data) {
    //     router.replace({
    //       name: 'whatsapp_template_take_a_look',
    //       params: {
    //         page: 'take_a_look',
    //         content_data: content_data,
    //         templateData: this.template_data,
    //       },
    //     });
    //   },
    },
  };
  </script>
  <style scoped>
  .custom-height{
    height: 93% !important;
  }
  </style>