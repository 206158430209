<template>
  <div
    class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] overflow-y-auto calculate-height"
  >
    <div class="w-full">
      <page-header :header-title="$t('WHATSAPP_TEMPLATES.TAKE.TITLE')" />
    </div>

    <div class="w-full relative-position">
      <take-form
        :on-submit="finishSetup"
        :submit-in-progress="false"
        :submit-button-text="$t('WHATSAPP_TEMPLATES.ADD.BUTTON_TEXT')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import TakeForm from '../TakeForm';

export default {
  components: {
    PageHeader,
    TakeForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      currentUserdata: null,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    headerTitle() {
      return this.$t('WHATSAPP_TEMPLATES.ADD.TITLE', {});
    },
  },
  mounted() {
    this.templateData = this.$route.params.templateData;
    this.content_data = this.$route.params.content_data;
    this.accountId = this.$route.params.accountId;
    this.currentUserdata = this.currentUser;
  },
  methods: {
    async finishSetup(data) {
      if (!this.currentUser) {
        this.showAlert(this.$t('USER_NOT_FOUND'));
        return;
      }
      const token = this.currentUser.access_token;
      if (!token) {
        this.showAlert(this.$t('TOKEN_NOT_FOUND'));
        return;
      }
      try {
        await this.$store.dispatch('whatsappTemplates/create', {
          accountId: this.currentAccountId,
          data: { ...data, token: token, user: this.currentUser },
        });
        router.replace({
          name: 'whatsapp_template_finish',
          params: {
            page: 'finish',
          },
        });
      } catch (error) {
        this.showAlert(this.$t(error));
      }
    },
  },
};
</script>
<style>
.relative-position{
  position: relative;
}
.calculate-height{
  max-height: calc(100% - 100px);
}
</style>
