<template>
  <div class="flex-grow flex-shrink min-w-0 overflow-auto">
    <div class="image p-8">
      <form @submit.prevent="handleSubmit">
        <div class="flex flex-col mb-6 w-[40%]">
          <div class="prev-content_data">
            <div id="preview">
              <img
                v-if="content_data.format === 'image' && content_data.file"
                :src="content_data.file"
              />
              <video
                v-if="content_data.format === 'video' && content_data.file"
                :src="content_data.file"
                controls
                playsInline
              >
                <woot-modal :show.sync="videoshow" :on-close="onVideoClose">
                  <video
                    :src="content_data.file"
                    controls
                    playsInline
                    class=""
                  />
                </woot-modal>
              </video>
              <iframe
                v-if="content_data.format === 'document' && content_data.file"
                :src="content_data.file"
              />
            </div>
            <p class="font-bold text-xl">{{ dynamicheader }}</p>
            <p class="font-semibold text-lg">{{ dynamicText }}</p>
            <p>{{ content_data.footer }}</p>
            <p class="text-end m-0">
              {{ content_data.hours }}:{{ content_data.minutes }}
            </p>
            <div v-if="content_data.buttonOption === 'ActionButton'">
              <a class="button-text flex justify-center align-center p-2 gap-1">
                <fluent-icon
                  icon="share-website-url"
                  type="outline"
                  view-box="0 0 20 14"
                />
                <button>{{ content_data.buttonText }}</button>
              </a>
              <a class="button-text flex justify-center align-center p-2 gap-1">
                <fluent-icon icon="phone-number" type="outline" />
                <button>{{ content_data.buttonText1 }}</button>
              </a>
            </div>
          </div>
          <div
            v-if="content_data.buttonOption === 'QuickReply'"
            class="flex text-center reply-box-wrap gap-0.5"
          >
            <a
              v-for="card in content_data.navigationCards"
              :key="card.id"
              class="reply-box"
            >
              <button class="reply-box-btn">{{ card.value }}</button>
            </a>
          </div>
          <div class="flex items-center gap-1.5 add_padding">
            <woot-submit-button
              :disabled="loading"
              :button-text="submitButtonText"
              :loading="loading"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [alertMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
      body: '',
      footer: '',
      buttonText: '',
      buttonText1: '',
      buttonOption: '',
      format: '',
      finalHeader: '',
      finalBody: '',
      content_data: {},
      templateData: {},
      videoshow: false,
      loading: false,
    };
  },
  computed: {
    isFlexButton() {
      return this.content_data.buttonOption === 'QuickReply';
    },
    dynamicheader() {
      if (
        this.content_data.header &&
        typeof this.content_data.header === 'string'
      ) {
        let splitArray;
        const dataArray = this.content_data.header.split(/\{\{\d+\}\}/g);
        let mainbody = this.content_data.header.split(' ');
        const variableArray = dataArray.filter(item => item !== '');
        const FilterInputItem = this.content_data.header_text.filter(
          item => item !== ''
        );
        if (mainbody.length > 0 && mainbody[0].startsWith('{{1}}')) {
          const final = [];
          variableArray.forEach((item, index) => {
            if (FilterInputItem[index]) {
              final[index] = FilterInputItem[index] + variableArray[index];
            }
          });
          const result =
            final.length === 0 ? splitArray.join(' ') : final.join(' ');
          return result;
        }
        splitArray = dataArray.filter(item => item !== '');
        const final = [];
        splitArray.forEach((item, index) => {
          if (FilterInputItem[index]) {
            final[index] = splitArray[index] + FilterInputItem[index];
          }
        });
        if (FilterInputItem.length < splitArray.length) {
          final[final.length] = splitArray[splitArray.length - 1];
        }
        const result =
          final.length === 0 ? splitArray.join(' ') : final.join(' ');
        return result;
      }
      return null;
    },
    dynamicText() {
      if (
        this.content_data.body &&
        typeof this.content_data.body === 'string'
      ) {
        let splitArray;
        const dataArray = this.content_data.body.split(/\{\{\d+\}\}/g);
        let mainbody = this.content_data.body.split(' ');
        const variableArray = dataArray.filter(item => item !== '');
        const FilterInputItem = this.content_data.body_text.filter(
          item => item !== ''
        );
        if (mainbody.length > 0 && mainbody[0].startsWith('{{1}}')) {
          const final = [];
          variableArray.forEach((item, index) => {
            if (FilterInputItem[index]) {
              final[index] = FilterInputItem[index] + variableArray[index];
            }
          });
          const result =
            final.length === 0 ? splitArray.join(' ') : final.join(' ');
          return result;
        }
        splitArray = dataArray.filter(item => item !== '');
        const final = [];
        splitArray.forEach((item, index) => {
          if (FilterInputItem[index]) {
            final[index] = splitArray[index] + FilterInputItem[index];
          }
        });
        if (FilterInputItem.length < splitArray.length) {
          final[final.length] = splitArray[splitArray.length - 1];
        }
        const result =
          final.length === 0 ? splitArray.join(' ') : final.join(' ');
        return result;
      }
      return null;
    },
  },
  mounted() {
    this.templateData = this.$route.params.templateData;
    this.inbox = this.templateData.inboxOption;
    this.language = this.templateData.locale;
    this.category = this.templateData.categoryOption;
    this.name = this.templateData.name;
    this.content_data = this.$route.params.content_data;
    this.file = this.content_data.file;
    this.filename = this.content_data.fileName;
    this.body = this.content_data.body;
    this.sample_body = this.content_data.body_text.filter(item => item !== '');
    this.header = this.content_data.header;
    this.sample_header = this.content_data.header_text.filter(
      item => item !== ''
    );
    this.footer = this.content_data.footer;
    this.phoneNumber = this.content_data.phoneNumber;
    this.websiteUrl = this.content_data.websiteUrl;
    this.sample_url = this.content_data.dynamicInput;
    this.buttonOption = this.content_data.buttonOption;
    this.buttonText = this.content_data.buttonText;
    this.buttonText1 = this.content_data.buttonText1;
    this.navigationCards = this.content_data.navigationCards;
  },
  methods: {
    setFinalHeader(result) {
      this.finalHeader = result;
    },
    setFinalBody(result) {
      this.finalBody = result;
    },
    getMergedContent(content_data) {
      const websiteUrl = content_data.websiteUrl
        ? content_data.websiteUrl.replace(
            /\{\{\}\}/g,
            content_data.dynamicInput
          )
        : '';
      return websiteUrl;
    },
    onVideoClose() {
      this.videoshow = false;
    },
    onClose() {
      this.show = false;
    },
    isImage(file) {
      return /\.(jpg|jpeg|png|gif|bmp)$/.test(file);
    },
    isVideo(file) {
      return /\.(mp4|avi|mov|mkv)$/i.test(file);
    },
    isPDF(file) {
      return /\.pdf$/i.test(file);
    },
    async handleSubmit() {
  try {
    console.log("Submitting");
    this.loading = true;

    // Wait for onSubmit to complete if it's an asynchronous operation
    await this.onSubmit({
      inbox: this.inbox,
      language: this.language,
      category: this.category,
      name: this.name,
      body: this.body,
      sample_body: this.sample_body,
      header: this.header,
      sample_header: this.sample_header,
      footer: this.footer,
      phoneNumber: this.phoneNumber,
      websiteUrl: this.websiteUrl,
      sample_url: this.sample_url,
      buttonOption: this.buttonOption,
      buttonText: this.buttonText,
      buttonText1: this.buttonText1,
      filename: this.filename,
      file: this.file,
      navigationCards: this.navigationCards,
      finalHeader: this.finalHeader,
      finalBody: this.finalBody,
    });

  } catch (error) {
    console.error("Error submitting the form", error);
  } finally {
    this.loading = false;
  }
}

  },
};
</script>

<style scoped>
input {
  @apply mb-4;
}
.image {
  background-image: url('~dashboard/assets/images/whatsapp.png');
}
.prev-content_data {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img,
video,
iframe {
  flex: 1;
  max-height: 200px;
}
.reply-box {
  border-top: 1px solid gainsboro;
  width: 50%;
  border-radius: 5px;
  background-color: white;
}
.reply-box-wrap {
  padding-top: 1px;
}
.reply-box-btn {
  padding: 8px 0px;
}
.button-text {
  border-top: 1px solid rgb(197, 196, 196);
}
.prev-content_data::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-bottom: 12px solid transparent;
  border-right: 12px solid white;
  position: absolute;
  top: 0px;
  left: -10px;
}
.add_padding{
  padding: 5px 0px;
  position: absolute;
  bottom: -50% !important;
}
</style>
