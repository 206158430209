<template>
  <div v-if="this.additionalAttributes.recording_url">
    <audio controls>
        <source
          :src=this.additionalAttributes.recording_url
          type="audio/mp3"
        />
      </audio>
  </div>
  
</template>

<script>
export default{
  props: {
    messageId: {
      type: [String, Number],
      default: 0,
    },
    additionalAttributes: {
      type: Object,
      default: () => ({}),
    },
    inboxId: {
      type: [String, Number],
      default: 0,
    },
  },

  computed: {
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
  },
};

</script>

<style scoped>
/* Add your component styles here */
</style>
