<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />
    <div class="w-[20%] flex-shrink-0 flex-grow-0 max-w-[20%]">
      <a href="#" @click="startLogin()">
        <img
          src="~dashboard/assets/images/channels/facebook_login.png"
          alt="Facebook-logo"
        />
      </a>
    </div>
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label>
        {{ $t("INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL") }}
        <select v-model="provider">
          <option value="whatsapp_cloud">
            {{ $t("INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD") }}
          </option>
          <option value="twilio">
            {{ $t("INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO") }}
          </option>
          <option value="360dialog">
            {{ $t("INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG") }}
          </option>
        </select>
      </label>
    </div>

    <twilio v-if="provider === 'twilio'" type="whatsapp" />
    <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" />
    <cloud-whatsapp v-else :userData="user_data" />
  </div>
</template>

<script>
import PageHeader from "../../SettingsSubPageHeader";
import Twilio from "./Twilio";
import LoadingState from "dashboard/components/widgets/LoadingState";
import ThreeSixtyDialogWhatsapp from "./360DialogWhatsapp";
import CloudWhatsapp from "./CloudWhatsapp";
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    CloudWhatsapp,
  },
  mixins: [alertMixin],
  data() {
    return {
      provider: "whatsapp_cloud",
      user_data: null,
      whatsAppDetails: null,
      phoneNumbers: null,
      accessToken: null,
      client_id: null,
      client_secret: null,
    };
  },

  created() {
    this.initFB();
    this.loadFBsdk();
    this.fetchEnvIndex();
  },

  mounted() {
    this.initFB();
    // this.getenvdata();
  },

  methods: {
    startLogin() {
      this.tryFBlogin();
    },

    async fetchEnvIndex() {
      try {
        const response = await axios.get('/env_index');
        
        this.client_secret = response.data.client_secret;
        this.client_id = response.data.client_id;
        // Handle the response data as needed
      } catch (error) {
        console.error("Error fetching env index:", error);
        // Handle error
      }
    },

    async fetchWhatsAppDetails(accessToken) {
      try {
        // Make an API call to get WhatsApp Business account details
        // console.log("24 hors access token: ");
        const app_access_token = `${this.client_id}|${this.client_secret}`;
        console.log("{App access token..............", app_access_token);
        const response = await axios.get("https://graph.facebook.com/v16.0/debug_token", {
          params: {
            input_token: accessToken,
            access_token: app_access_token
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const granular_scopes = response.data.data.granular_scopes;
        let data = granular_scopes.filter((data) => {
          if (data.scope == "whatsapp_business_management") {
            return 1;
          }
        });
        const waba_id = data[0].target_ids[0];
        console.log("WhatsApp Business Account Details:",waba_id);
        // Handle the response data as needed
        this.whatsAppDetails = {id: waba_id};
      } catch (error) {
        console.error("Error fetching WhatsApp details:", error);
        // Handle error
      }
    },

    async exchangeForLongLivedToken(shortLivedToken) {
      try {
      const client_id = this.client_id;
      const client_secret = this.client_secret;
      const response = await fetch(
        `https://graph.facebook.com/v10.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${client_id}&client_secret=${client_secret}&fb_exchange_token=${shortLivedToken}`
      );
      const data = await response.json();
      // console.log('Long-lived token:', data.access_token);
      this.accessToken = data.access_token;
      // Store the long-lived token for later use
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching WhatsApp details:', error);
        // Handle error
      }
    },

    async fetchPhoneNumbers(accessToken, businessAccountId) {
      try {
        // Make an API call to get phone numbers associated with the WhatsApp Business account
        const response = await axios.get(
          `https://graph.facebook.com/v16.0/${businessAccountId}/phone_numbers`,
          {
            params: {
              access_token: accessToken,
              sort: ['last_onboarded_time_descending']
            },
          }
        );
        const latest_linked_phone_number = response.data.data[0];
        this.phoneNumbers = {
          id: latest_linked_phone_number.id,
          phoneNumber: latest_linked_phone_number.display_phone_number,
        }
        console.log('WhatsApp Phone Numbers:', this.phoneNumbers);
      } catch (error) {
        console.error("Error fetching phone numbers:", error);
        // Handle error
      }
    },

    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.chatwootConfig.fbAppId,
            xfbml: true,
            version: window.chatwootConfig.fbApiVersion,
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },

    loadFBsdk() {
      ((d, s, id) => {
        let js;
        // eslint-disable-next-line
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },

    tryFBlogin() {
      const extras = {
        feature: "whatsapp_embedded_signup",
      };
      FB.login(
        async (response) => {
          if (response.status === "connected") {
            console.log("RESPONSE::::::::::::::::::::", response);
            const accessToken = response.authResponse.accessToken;
            await this.exchangeForLongLivedToken(accessToken);
             //this.accessToken = accessToken;
            // eslint-disable-next-line no-console
            console.log('extended token: ', this.accessToken);
            if (this.accessToken) {
              await this.fetchWhatsAppDetails(this.accessToken);
            }

            if (this.whatsAppDetails && this.whatsAppDetails.id) {
              await this.fetchPhoneNumbers(
                this.accessToken,
                this.whatsAppDetails.id
              );
            }
            this.user_data = {
              inboxName: 'Whatsapp_inbox',
              businessAccountId: this.whatsAppDetails.id,
              phoneNumber: this.phoneNumbers.phoneNumber.replace(/ /g, ''),
              phoneNumberId: this.phoneNumbers.id,
              apiKey: this.accessToken,
            }
            console.log("Final Data:::",this.user_data);
          } else if (response.status === "not_authorized") {
            // The person is logged into Facebook, but not your app.
            this.emptyStateMessage = this.$t("INBOX_MGMT.DETAILS.ERROR_FB_AUTH");
          } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            this.emptyStateMessage = this.$t("INBOX_MGMT.DETAILS.ERROR_FB_AUTH");
          }
        },
        {
          scope:
            "business_management,whatsapp_business_management,whatsapp_business_messaging",
          extras: JSON.stringify(extras),
        }
      );
    },
  },
};
</script>