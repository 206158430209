export default {
  methods:{
    async getKeys(){
      try {
        const response = await axios.get('/env_index');
        return response.data
      } catch (error) {
        console.error("Error fetching env index:", error);
      }  
    }
  }
}