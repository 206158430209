export const timezones = [
    { key: "Asia/Kabul",value: "UTC +04:30" },
    { key: "Africa/Algiers", value: "UTC +01:00" },
    { key: "Pacific/Pago_Pago", value: "UTC -11:00" },
    { key: "Europe/Andorra", value: "UTC +02:00" },
    { key: "Africa/Luanda", value: "UTC +01:00" },
    { key: "America/Anguilla", value: "UTC -04:00" },
    { key: "Antarctica/Casey", value: "UTC +08:00" },
    { key: "Antarctica/Davis", value: "UTC +07:00" },
    { key: "Antarctica/DumontDUrville", value: "UTC +10:00" },
    { key: "Antarctica/Mawson", value: "UTC +05:00" },
    { key: "Antarctica/McMurdo", value: "UTC +12:00" },
    { key: "Antarctica/Palmer", value: "UTC -03:00" },
    { key: "Antarctica/Rothera", value: "UTC -03:00" },
    { key: "Antarctica/Syowa", value: "UTC +03:00" },
    { key: "Antarctica/Troll", value: "UTC +02:00" },
    { key: "Antarctica/Vostok", value: "UTC +05:00" },
    { key: "America/Antigua", value: "UTC -04:00" },
    { key: "America/Argentina/Buenos_Aires", value: "UTC -03:00" },
    { key: "America/Argentina/Catamarca", value: "UTC -03:00" },
    { key: "America/Argentina/Cordoba", value: "UTC -03:00" },
    { key: "America/Argentina/Jujuy", value: "UTC -03:00" },
    { key: "America/Argentina/La_Rioja", value: "UTC -03:00" },
    { key: "America/Argentina/Mendoza", value: "UTC -03:00" },
    { key: "America/Argentina/Rio_Gallegos", value: "UTC -03:00" },
    { key: "America/Argentina/Salta", value: "UTC -03:00" },
    { key: "America/Argentina/San_Juan", value: "UTC -03:00" },
    { key: "America/Argentina/San_Luis", value: "UTC -03:00" },
    { key: "America/Argentina/Tucuman", value: "UTC -03:00" },
    { key: "America/Argentina/Ushuaia", value: "UTC -03:00" },
    { key: "Asia/Yerevan", value: "UTC +04:00" },
    { key: "America/Aruba", value: "UTC -04:00" },
    { key: "Antarctica/Macquarie", value: "UTC +10:00" },
    { key: "Australia/Adelaide", value: "UTC +09:30" },
    { key: "Australia/Brisbane", value: "UTC +10:00" },
    { key: "Australia/Broken_Hill", value: "UTC +09:30" },
    { key: "Australia/Darwin", value: "UTC +09:30" },
    { key: "Australia/Eucla", value: "UTC +08:45" },
    { key: "Australia/Hobart", value: "UTC +10:00" },
    { key: "Australia/Lindeman", value: "UTC +10:00" },
    { key: "Australia/Lord_Howe", value: "UTC +10:30" },
    { key: "Australia/Melbourne", value: "UTC +10:00" },
    { key: "Australia/Perth", value: "UTC +08:00" },
    { key: "Australia/Sydney", value: "UTC +10:00" },
    { key: "Europe/Vienna", value: "UTC +02:00" },
    { key: "Asia/Baku", value: "UTC +04:00" },
    { key: "America/Nassau", value: "UTC -04:00" },
    { key: "Asia/Bahrain", value: "UTC +03:00" },
    { key: "Asia/Dhaka", value: "UTC +06:00" },
    { key: "America/Barbados", value: "UTC -04:00" },
    { key: "Europe/Minsk", value: "UTC +03:00" },
    { key: "Europe/Brussels", value: "UTC +02:00" },
    { key: "America/Belize", value: "UTC -06:00" },
    { key: "Africa/Porto-Novo", value: "UTC +01:00" },
    { key: "Atlantic/Bermuda", value: "UTC -03:00" },
    { key: "Asia/Thimphu", value: "UTC +06:00" },
    { key: "America/Kralendijk", value: "UTC -04:00" },
    { key: "Europe/Sarajevo", value: "UTC +02:00" },
    { key: "Africa/Gaborone", value: "UTC +02:00" },
    { key: "America/Araguaina", value: "UTC -03:00" },
    { key: "America/Bahia", value: "UTC -03:00" },
    { key: "America/Belem", value: "UTC -03:00" },
    { key: "America/Boa_Vista", value: "UTC -04:00" },
    { key: "America/Campo_Grande", value: "UTC -04:00" },
    { key: "America/Cuiaba", value: "UTC -04:00" },
    { key: "America/Eirunepe", value: "UTC -05:00" },
    { key: "America/Fortaleza", value: "UTC -03:00" },
    { key: "America/Maceio", value: "UTC -03:00" },
    { key: "America/Manaus", value: "UTC -04:00" },
    { key: "America/Noronha", value: "UTC -02:00" },
    { key: "America/Porto_Velho", value: "UTC -04:00" },
    { key: "America/Recife", value: "UTC -03:00" },
    { key: "America/Rio_Branco", value: "UTC -05:00" },
    { key: "America/Santarem", value: "UTC -03:00" },
    { key: "America/Sao_Paulo", value: "UTC -03:00" },
    { key: "Indian/Chagos", value: "UTC +06:00" },
    { key: "Asia/Brunei", value: "UTC +08:00" },
    { key: "Europe/Sofia", value: "UTC +03:00" },
    { key: "Africa/Ouagadougou", value: "UTC" },
    { key: "Africa/Bujumbura", value: "UTC +02:00" },
    { key: "Asia/Phnom_Penh", value: "UTC +07:00" },
    { key: "Africa/Douala", value: "UTC +01:00" },
    { key: "America/Atikokan", value: "UTC -05:00" },
    { key: "America/Blanc-Sablon", value: "UTC -04:00" },
    { key: "America/Cambridge_Bay", value: "UTC -06:00" },
    { key: "America/Creston", value: "UTC -07:00" },
    { key: "America/Dawson", value: "UTC -07:00" },
    { key: "America/Dawson_Creek", value: "UTC -07:00" },
    { key: "America/Edmonton", value: "UTC -06:00" },
    { key: "America/Hermosillo", value: "UTC -07:00" },
    { key: "America/Inuvik", value: "UTC -06:00" },
    { key: "America/Iqaluit", value: "UTC -04:00" },
    { key: "America/Jamestown", value: "UTC -05:00" },
    { key: "America/La_Paz", value: "UTC -04:00" },
    { key: "America/Managua", value: "UTC -06:00" },
    { key: "America/Marigot", value: "UTC -04:00" },
    { key: "America/Martinique", value: "UTC -04:00" },
    { key: "America/Mexico_City", value: "UTC -06:00" },
    { key: "America/Montreal", value: "UTC -04:00" },
    { key: "America/Nipigon", value: "UTC -05:00" },
    { key: "America/Panama", value: "UTC -05:00" },
    { key: "America/Rankin_Inlet", value: "UTC -06:00" },
    { key: "America/Regina", value: "UTC -06:00" },
    { key: "America/Resolute", value: "UTC -06:00" },
    { key: "America/Tegucigalpa", value: "UTC -06:00" },
    { key: "America/Thunder_Bay", value: "UTC -05:00" },
    { key: "America/Toronto", value: "UTC -04:00" },
    { key: "America/Vancouver", value: "UTC -07:00" },
    { key: "America/Whitehorse", value: "UTC -07:00" },
    { key: "America/Winnipeg", value: "UTC -06:00" },
    { key: "Asia/Shanghai", value: "UTC +08:00" },
    { key: "Asia/Taipei", value: "UTC +08:00" },
    { key: "Asia/Urumqi", value: "UTC +06:00" },
    { key: "Europe/Chisinau", value: "UTC +03:00" },
    { key: "Europe/Cologne", value: "UTC +02:00" },
    { key: "Europe/Dublin", value: "UTC +01:00" },
    { key: "Europe/Guernsey", value: "UTC +01:00" },
    { key: "Europe/Isle_of_Man", value: "UTC +01:00" },
    { key: "Europe/Jersey", value: "UTC +01:00" },
    { key: "Europe/Lisbon", value: "UTC +01:00" },
    { key: "Europe/Monaco", value: "UTC +02:00" },
    { key: "Europe/Paris", value: "UTC +02:00" },
    { key: "Europe/Prague", value: "UTC +02:00" },
    { key: "Europe/Rome", value: "UTC +02:00" },
    { key: "Europe/Tirane", value: "UTC +02:00" },
    { key: "Europe/Vatican", value: "UTC +02:00" },
    { key: "Europe/Warsaw", value: "UTC +02:00" },
    { key: "Europe/Zagreb", value: "UTC +02:00" },
    { key: "Indian/Maldives", value: "UTC +05:00" },
    { key: "Indian/Mauritius", value: "UTC +04:00" },
    { key: "Pacific/Auckland", value: "UTC +13:00" },
    { key: "Pacific/Fiji", value: "UTC +12:00" },
    { key: "Pacific/Funafuti", value: "UTC +12:00" },
    { key: "Pacific/Kiritimati", value: "UTC +14:00" },
    { key: "Pacific/Marquesas", value: "UTC -09:30" },
    { key: "Pacific/Nauru", value: "UTC +12:00" },
    { key: "Pacific/Niue", value: "UTC -11:00" },
    { key: "Pacific/Ponape", value: "UTC +11:00" },
    { key: "Pacific/Port_Moresby", value: "UTC +10:00" },
    { key: "Pacific/Tarawa", value: "UTC +12:00" },
    { key: "Pacific/Truk", value: "UTC +10:00" },
    { key: "Asia/Hong_Kong", value: "UTC +08:00" },
    { key: "Asia/Jakarta", value: "UTC +07:00" },
    { key: "Asia/Kolkata", value: "UTC +05:30" },
    { key: "Asia/Krasnoyarsk", value: "UTC +07:00" },
    { key: "Asia/Kuala_Lumpur", value: "UTC +08:00" },
    { key: "Asia/Makassar", value: "UTC +08:00" },
    { key: "Asia/Manila", value: "UTC +08:00" },
    { key: "Asia/Nicosia", value: "UTC +03:00" },
    { key: "Asia/Novosibirsk", value: "UTC +07:00" },
    { key: "Asia/Seoul", value: "UTC +09:00" },
    { key: "Asia/Singapore", value: "UTC +08:00" },
    { key: "Asia/Tokyo", value: "UTC +09:00" },
    { key: "Asia/Ulaanbaatar", value: "UTC +08:00" },
    { key: "Asia/Yakutsk", value: "UTC +09:00" },
    { key: "Asia/Yekaterinburg", value: "UTC +05:00" },
    { key: "Europe/Astrakhan", value: "UTC +04:00" },
    { key: "Europe/Kaliningrad", value: "UTC +02:00" },
    { key: "Europe/Kirov", value: "UTC +03:00" },
    { key: "Europe/Moscow", value: "UTC +03:00" },
    { key: "Europe/Samara", value: "UTC +04:00" },
    { key: "Europe/Saratov", value: "UTC +04:00" },
    { key: "Europe/Ulyanovsk", value: "UTC +04:00" },
    { key: "Europe/Volgograd", value: "UTC +03:00" },
    { key: "Africa/Kigali", value: "UTC +02:00" },
    { key: "Indian/Reunion", value: "UTC +04:00" },
    { key: "America/St_Barthelemy", value: "UTC -04:00" },
    { key: "Atlantic/St_Helena", value: "UTC" },
    { key: "America/St_Kitts", value: "UTC -04:00" },
    { key: "America/St_Lucia", value: "UTC -04:00" },
    { key: "America/Miquelon", value: "UTC -02:00" },
    { key: "America/St_Vincent", value: "UTC -04:00" },
    { key: "Pacific/Apia", value: "UTC +13:00" },
    { key: "Europe/San_Marino", value: "UTC +02:00" },
    { key: "Africa/Sao_Tome", value: "UTC" },
    { key: "Asia/Riyadh", value: "UTC +03:00" },
    { key: "Africa/Dakar", value: "UTC" },
    { key: "Europe/Belgrade", value: "UTC +02:00" },
    { key: "Indian/Mahe", value: "UTC +04:00" },
    { key: "Africa/Freetown", value: "UTC" },
    { key: "America/Lower_Princes", value: "UTC -04:00" },
    { key: "Europe/Bratislava", value: "UTC +02:00" },
    { key: "Europe/Ljubljana", value: "UTC +02:00" },
    { key: "Pacific/Guadalcanal", value: "UTC +11:00" },
    { key: "Africa/Mogadishu", value: "UTC +03:00" },
    { key: "Africa/Johannesburg", value: "UTC +02:00" },
    { key: "Atlantic/South_Georgia", value: "UTC -02:00" },
    { key: "Africa/Juba", value: "UTC +02:00" },
    { key: "Africa/Ceuta", value: "UTC +02:00" },
    { key: "Atlantic/Canary", value: "UTC +01:00" },
    { key: "Europe/Madrid", value: "UTC +02:00" },
    { key: "Asia/Colombo", value: "UTC +05:30" },
    { key: "Africa/Khartoum", value: "UTC +02:00" },
    { key: "America/Paramaribo", value: "UTC -03:00" },
    { key: "Arctic/Longyearbyen", value: "UTC +02:00" },
    { key: "Africa/Mbabane", value: "UTC +02:00" },
    { key: "Europe/Stockholm", value: "UTC +02:00" },
    { key: "Europe/Zurich", value: "UTC +02:00" },
    { key: "Asia/Damascus", value: "UTC +03:00" },
    { key: "Asia/Dushanbe", value: "UTC +05:00" },
    { key: "Africa/Dar_es_Salaam", value: "UTC +03:00" },
    { key: "Asia/Bangkok", value: "UTC +07:00" },
    { key: "Asia/Dili", value: "UTC +09:00" },
    { key: "Africa/Lome", value: "UTC" },
    { key: "Pacific/Fakaofo", value: "UTC +13:00" },
    { key: "Pacific/Tongatapu", value: "UTC +13:00" },
    { key: "America/Port_of_Spain", value: "UTC -04:00" },
    { key: "Africa/Tunis", value: "UTC +01:00" },
    { key: "Europe/Istanbul", value: "UTC +03:00" },
    { key: "Asia/Ashgabat", value: "UTC +05:00" },
    { key: "America/Grand_Turk", value: "UTC -04:00" },
    { key: "Africa/Kampala", value: "UTC +03:00" },
    { key: "Europe/Kyiv", value: "UTC +03:00" },
    { key: "Europe/Simferopol", value: "UTC +03:00" },
    { key: "Asia/Dubai", value: "UTC +04:00" },
    { key: "Europe/London", value: "UTC +01:00" },
    { key: "America/Adak", value: "UTC -09:00" },
    { key: "America/Anchorage", value: "UTC -08:00" },
    { key: "America/Boise", value: "UTC -06:00" },
    { key: "America/Chicago", value: "UTC -05:00" },
    { key: "America/Denver", value: "UTC -06:00" },
    { key: "America/Detroit", value: "UTC -04:00" },
    { key: "America/Indiana/Indianapolis", value: "UTC -04:00" },
    { key: "America/Indiana/Knox", value: "UTC -05:00" },
    { key: "America/Indiana/Marengo", value: "UTC -04:00" },
    { key: "America/Indiana/Petersburg", value: "UTC -04:00" },
    { key: "America/Indiana/Tell_City", value: "UTC -05:00" },
    { key: "America/Indiana/Vevay", value: "UTC -04:00" },
    { key: "America/Indiana/Vincennes", value: "UTC -04:00" },
    { key: "America/Indiana/Winamac", value: "UTC -04:00" },
    { key: "America/Juneau", value: "UTC -08:00" },
    { key: "America/Kentucky/Louisville", value: "UTC -04:00" },
    { key: "America/Kentucky/Monticello", value: "UTC -04:00" },
    { key: "America/Los_Angeles", value: "UTC -07:00" },
    { key: "America/Menominee", value: "UTC -05:00" },
    { key: "America/Metlakatla", value: "UTC -08:00" },
    { key: "America/New_York", value: "UTC -04:00" },
    { key: "America/Nome", value: "UTC -08:00" },
    { key: "America/North_Dakota/Beulah", value: "UTC -05:00" },
    { key: "America/North_Dakota/Center", value: "UTC -05:00" },
    { key: "America/North_Dakota/New_Salem", value: "UTC -05:00" },
    { key: "America/Phoenix", value: "UTC -07:00" },
    { key: "America/Sitka", value: "UTC -08:00" },
    { key: "America/Yakutat", value: "UTC -08:00" },
    { key: "Pacific/Honolulu", value: "UTC -10:00" },
    { key: "Pacific/Midway", value: "UTC -11:00" },
    { key: "Pacific/Wake", value: "UTC +12:00" },
    { key: "America/Montevideo", value: "UTC -03:00" },
    { key: "Asia/Samarkand", value: "UTC +05:00" },
    { key: "Asia/Tashkent", value: "UTC +05:00" },
    { key: "Pacific/Efate", value: "UTC +11:00" },
    { key: "America/Caracas", value: "UTC -04:00" },
    { key: "Asia/Ho_Chi_Minh", value: "UTC +07:00" },
    { key: "America/Tortola", value: "UTC -04:00" },
    { key: "America/St_Thomas", value: "UTC -04:00" },
    { key: "Pacific/Wallis", value: "UTC +12:00" },
    { key: "Asia/Aden", value: "UTC +03:00" },
    { key: "Africa/Lusaka", value: "UTC +02:00" },
    { key: "Africa/Harare", value: "UTC +02:00" },
    { key: "Europe/Mariehamn", value: "UTC +03:00" }
]

