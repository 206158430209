export const getters = {
  getContacts($state) {
    return $state.sortOrder.map(contactId => $state.records[contactId]);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContact: $state => id => {
    const contact = $state.records[id];
    return contact || {};
  },
  getMeta: $state => {
    return $state.meta;
  },
  getAppliedContactFilters: _state => {
    return _state.appliedFilters;
  },
  getIncomingCall: _state => {
    return _state.isIncoming;
  },
  getHoldState: _state => {
    return _state.isHold;
  },
  getHoldui: _state => {
    return _state.isHoldui
  },
  getHoldCalldata: _state=>{
    return _state.holdCall;
  },
  getHoldCallUidata: _state=>{
    return _state.holdcalldata;
  }
};
