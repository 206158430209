import { required } from 'vuelidate/lib/validators';

export default {
  name: {
    required,
  },
  categoryOption: {
    required,
  },
};
