<template>
  <div class="mx-0 flex flex-wrap">
    <div class="flex-shrink-0 flex-grow-0 w-[92%]">
      
      <!-- <ul class="text-sm w-full text-slate-500 dark:text-slate-200">
        <li>
          {{ $t('WHATSAPP_TEMPLATES.CREATE_FLOW.REMARKS.LIST1') }}
        </li>
        <li>
          {{ $t('WHATSAPP_TEMPLATES.CREATE_FLOW.REMARKS.LIST2') }}
        </li>
        <li>
          {{ $t('WHATSAPP_TEMPLATES.CREATE_FLOW.REMARKS.LIST3') }}
        </li>
        <li>
          {{ $t('WHATSAPP_TEMPLATES.CREATE_FLOW.REMARKS.LIST4') }}
        </li>
        <li>
          {{ $t('WHATSAPP_TEMPLATES.CREATE_FLOW.REMARKS.LIST5') }}
        </li>
      </ul> -->
      <form class="mx-0 flex flex-wrap" @submit.prevent="handleSubmit">
       

        <div class="flex gap-x-5 w-full mb-2">
          <label class="w-[55%]">
            {{ $t('WHATSAPP_TEMPLATES.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              class="mb-0"
              @input="$v.name.$touch"
              :disabled="isDisabled"
            />
            <span class="text-sm font-normal">
              {{ $t('WHATSAPP_TEMPLATES.FORM.NAME.PLACEHOLDER') }}
            </span>
          </label>

          <label class="w-[45%]">
            {{ $t('WHATSAPP_TEMPLATES.FORM.CATEGORY.LABEL') }}
            <select id="dropdown" v-model="categoryOption" class="mb-0" :disabled="isSelectCategory">
              <option value="MARKETING">Marketing</option>
              <option value="UTILITY">Utility</option>
            </select>
            <span class="text-sm font-normal">
              {{ $t('WHATSAPP_TEMPLATES.FORM.CATEGORY.PLACEHOLDER') }}
            </span>
          </label>
          <!-- <label class="w-[55%]">
            {{ $t('WHATSAPP_TEMPLATES.FORM.INBOX.LABEL') }}
            <select v-model="inboxOption" class="mb-0">
              <option
                v-for="inbox in this.inboxLists"
                :key="inbox.inbox_name"
                :value="inbox.inbox_name"
                >{{ inbox.inbox_name }}
              </option>
            </select>
            <span class="text-sm font-normal">
              {{ $t('WHATSAPP_TEMPLATES.FORM.INBOX.PLACEHOLDER') }}
            </span>
          </label> -->

       

        </div>
        <div class="flex gap-x-5 w-full mb-2">
          <!-- <label class="w-[55%]">
            {{ $t('WHATSAPP_TEMPLATES.FORM.INBOX.LABEL') }}
            <select v-model="inboxOption" class="mb-0">
              <option
                v-for="inbox in this.inboxLists"
                :key="inbox.inbox_name"
                :value="inbox.inbox_name"
                >{{ inbox.inbox_name }}
              </option>
            </select>
            <span class="text-sm font-normal">
              {{ $t('WHATSAPP_TEMPLATES.FORM.INBOX.PLACEHOLDER') }}
            </span>
          </label> -->

          <label class="w-[45%]">
            <!-- {{ $t('WHATSAPP_TEMPLATES.FORM.LANGUAGE.LABEL') }} -->
            <select v-model="locale" class="mb-0" :disabled="isSelectDisabled">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span class="text-sm font-normal">
              {{ $t('WHATSAPP_TEMPLATES.FORM.LANGUAGE.PLACEHOLDER') }}
            </span>
          </label>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0">
          <!-- <woot-submit-button
            :disabled="$v.name.$invalid || submitInProgress"
            :button-text="submitButtonText"
            :loading="submitInProgress"
          /> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import validations from './helpers/validations';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  props: {
    inboxLists: {
      type: Array,
      default: () => [],
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
    disablename: {
      type: Boolean,
      default: false
    },
    disableCategory: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    this.isDisabled=this.disablename;
    this.isSelectCategory=this.disableCategory;
  },
  data() {
    const formData = this.formData || {};
    const {
      inboxOption = '',
      locale = 'en',
      name = '',
      categoryOption = 'MARKETING',
    } = formData;

    return {
      inboxOption,
      locale,
      name,
      categoryOption,
      features: {},
      updateInbox: [],
      isDisabled: false,
      isSelectDisabled: true,
      isSelectCategory: false,
    };
  },
  validations,
  computed: {
    languagesSortedByCode() {
      if (this.enabledLanguages) {
        console.log("This enable",this.enabledLanguage);
        const enabledLanguages = [...this.enabledLanguages];
        return enabledLanguages.sort((l1, l2) =>
          l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
        );
      }
      return [];
    },
  },
  watch: {
    // Watch for changes in form data and emit the updated data
    // $route(to, from) {
    //   // Check if the route has the `templateId` parameter
    //   console.log('Template:::::::', to.params);
    //   if (to.params.templateId) {
    //     console.log('Template ID found:', to.params.templateId);
    //     this.isDisabled= true;
    //   } else {
    //     this.isDisabled= false;
    //     console.log('No template ID in route');
    //   }
    // },
    locale() {
      this.emitData();
    },
    name() {
      this.emitData();
    },
    categoryOption() {
      this.emitData();
    },
  },
  methods: {
    emitData() {
      // Emit data to parent
      this.$emit('update', {
        locale: this.locale,
        name: this.name,
        categoryOption: this.categoryOption,
        // inboxOption: this.inboxOption, // Uncomment if needed
      });
    },
    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.onSubmit({
        inboxOption: this.$route.params.inboxId,
        locale: this.locale,
        name: this.name,
        categoryOption: this.categoryOption,
      });
    },
  },
};
</script>
