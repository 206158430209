export default {
  watch: {
    header(headerBody) {
      if (
        headerBody.trim().slice(-1) !== ' ' ||
        headerBody.trim().slice(-1) !== '}'
      ) {
        this.restrictionHeader = false;
      }
      const headercurrentCount = (headerBody.match(/{{\d+}}/g) || []).length;
      if (headercurrentCount < this.previousHeaderCount) {
        this.headerCount = headercurrentCount;
      }
      this.previousHeaderCount = headercurrentCount;
      if (this.headerCount === 0) {
        this.showheader = false;
      }
    },
    body(newBody) {
      if (
        newBody.trim().slice(-1) !== ' ' ||
        newBody.trim().slice(-1) !== '}'
      ) {
        this.restrictionBody = false;
      }
      const FilterArray = newBody.split(/\{\{\d+\}\}/g);
      const newFilterArray = FilterArray.filter(item => item.trim() !== '');
      const IncludeArray = newBody.split(' ');
      const variableCount = (newBody.match(/{{\d+}}/g) || []).length;
      if (variableCount < this.previousVariableCount) {
        this.clickCount = variableCount;
      }
      this.previousVariableCount = variableCount;
      if (this.clickCount === 0) {
        this.showmessage = false;
        this.messageVisible = false;
      }
      const containselement = IncludeArray.some(element =>
        /{{\d+}}/.test(element)
      );
      if (newFilterArray.length > 0 && containselement) {
        const wordCounts = newFilterArray.map(element => {
          const words = element.split(' ').filter(word => word.trim() !== '');
          return words.length;
        });
        const lastElement = IncludeArray[IncludeArray.length - 1].trim();
        if (/\{\{\d+\}\}/.test(lastElement)) {
          this.messageVisible = true;
        } else if (wordCounts[wordCounts.length - 1] >= 2) {
          this.messageVisible = false;
        }
      }
    },
    buttonOption(newOption) {
      if (newOption === 'none') {
        this.variableRelatedToActionButton = '';
        this.variableRelatedToQuickReply = '';
      }
    },
    format(newOption, oldOption) {
      if (newOption !== oldOption) {
        this.header = '';
        this.clickCount = 0;
        this.fileName = '';
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$v.$touch();
      this.onSubmit({
        format: this.format,
        header: this.header,
        header_text: this.header_text,
        body: this.body,
        body_text: this.body_text,
        file: this.file,
        fileName: this.fileName,
        footer: this.footer,
        buttonOption: this.buttonOption,
        buttonText: this.buttonText,
        buttonText1: this.buttonText1,
        websiteUrl: this.websiteUrl,
        dynamicInput: this.dynamicInput,
        phoneNumber: this.phoneNumber,
        navigationCards: this.navigationCards,
        replyButton: this.replyButton,
        clickCount: this.clickCount,
        finalbody: this.finalbody,
        hours: this.hours,
        minutes: this.minutes,
      });
      const blank = this.replyButtonValue;
      if (blank) {
        this.navigationCards.push({
          id: 'replyButton',
          label: 'Button text',
          value: blank,
        });
      }
    },

    addVariable() {
      this.showmessage = true;
      this.updateInputBox();
    },
    updateInputBox() {
      const variableCount = (this.body.match(/{{\d+}}/g) || []).length;
      this.clickCount = variableCount;
      const lastChar = this.body.trim().slice(-1);
      if (lastChar === ' ' || lastChar === '}') {
        this.restrictionBody = true;
      } else if (this.clickCount < 5) {
        this.body = `${this.previousInputBoxData} {{${this.clickCount +
          1}}}&nbsp;`;
        this.finalbody = this.body;
        this.clickCount = variableCount + 1;
      }
    },
    headerInputBox() {
      this.showheader = true;
      this.updateheaderBox();
    },
    updateheaderBox() {
      const headercurrentCount = (this.header.match(/{{\d+}}/g) || []).length;
      const lastChar = this.header.trim().slice(-1);
      if (lastChar === ' ' || lastChar === '}') {
        this.restrictionHeader = true;
      } else if (this.headerCount < 1) {
        this.header += ' {{' + (headercurrentCount + 1) + '}} ';
        this.headerfinalBody = this.header;
        this.headerCount = headercurrentCount + 1;
        this.previousInputHeaderData = this.header;
      }
    },
    isInvalidUrl(url) {
      if (url === '') {
        return false;
      }
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return !urlPattern.test(url);
    },
    isInvalidPhoneNumber(phoneNumber) {
      if (phoneNumber === '') {
        return false;
      }
      const phonePattern = /^[0-9]{10}$/;
      return !phonePattern.test(phoneNumber);
    },
    addButtonVariable() {
      this.websiteUrl = this.dynamicEnabled ? '' : `${this.websiteUrl}{{}}`;
    },
    updateDynamicValue() {
      this.dynamicInput = this.dynamicEnabled
        ? this.dynamicValue
        : this.dynamicInput;
    },
    addNavigationCard() {
      this.navigationCards.push({
        id: 'card-' + (this.navigationCards.length + 1),
        label: 'Button text',
        value: '',
      });
    },
    validateCharacterLimit() {
      if (this.format === 'text') {
        if (this.header.length > this.maxCharacterLimit) {
          this.characterLimitError = 'Character limit exceeded';
          this.header = this.header.slice(0, this.maxCharacterLimit);
        } else {
          this.characterLimitError = '';
          this.previousInputHeaderData = this.header;
        }
      }
    },
    saveInputBoxData() {
      this.previousInputBoxData = this.body;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const fileType = file.type;
        if (
          (this.format === 'image' && fileType.startsWith('image/')) ||
          (this.format === 'video' && fileType.startsWith('video/')) ||
          (this.format === 'document' && fileType === 'application/pdf')
        ) {
          this.url = URL.createObjectURL(file);
          this.file = this.url;
          this.fileName = file.name;
        } else {
          this.url = null;
          this.file = null;
          this.fileName = null;
        }
      }
    },
  },
};
