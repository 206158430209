<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="handleSubmit">
    <div class="flex-row border-b border-slate-25 dark:border-slate-800 p-0.5 border-b-0">
      <div class="w-[75%]">
        <div class="flex-grow-0 flex-shrink-0 flex-[50%]">
          <!-- FOR HEADER BOX -->
          <input type="checkbox" v-model="headerCheck"/>
          <label class="text-base font-bold pb-2">
            {{ $t('WHATSAPP_TEMPLATES.FORM.HEADER.LABEL') }}
            <span class="text-xs pl-3">
              {{ $t('WHATSAPP_TEMPLATES.FORM.HEADER.PLACEHOLDER') }}
            </span>
          </label>
          <!-- <p>
            {{ $t('WHATSAPP_TEMPLATES.FORM.DESCRIPTION.DESC') }}
          </p> -->
          <div v-if="headerCheck" class="mb-6">
            <div class="flex gap-4">
              <select id="dropdown" v-model="format" class="w-[20%] mb-0">
                <option value="text">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE1.LABEL') }}
                </option>
                <!-- <option value="image">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE2.LABEL') }}
                </option>
                <option value="video">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE3.LABEL') }}
                </option>
                <option value="document">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE4.LABEL') }}
                </option> -->
              </select>
              <div class="relative w-full">
                <input
                  v-model="header"
                  type="text"
                  :disabled="format !== 'text'"
                  @input="validateCharacterLimit"
                />
                <p v-if="restrictionHeader" class="required-asterisk">
                  *Add only one Variable
                </p>
                <span class="text-xs absolute right-2 top-4 text-gray-500">
                  {{ header.length }}/{{ maxCharacterLimit }}
                </span>
              </div>
            </div>
            <div v-if="format === 'text'" class="character-limit-error">
              {{ characterLimitError }}
            </div>
            <div class="flex float-right cursor-pointer">
              <div :disabled="headerCount >= 1" @click.prevent="headerInputBox">
                {{ $t('WHATSAPP_TEMPLATES.FORM.ADDVARIABLE.DESC') }}
              </div>
            </div>
          </div>

          <label v-if="['image', 'video', 'document'].includes(format)">
            <div class="upload-image-btn">
              <input
                v-if="format === 'image'"
                type="file"
                accept="image/*"
                @change="onFileChange"
              />
              <input
                v-else-if="format === 'video'"
                type="file"
                accept="video/*"
                @change="onFileChange"
              />
              <input
                v-else-if="format === 'document'"
                type="file"
                accept=".pdf"
                @change="onFileChange"
              />
              <div class="flex gap-2">
                <button class="button buttonDisplay">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ADDSAMPLE.DESC') }}
                </button>
                <span class="text-sm pt-2">{{ fileName }}</span>
              </div>
            </div>
          </label>
          <div class="body-content">
            <div v-if="showheader&& headerCheck" class="p-4">
              <!-- <h6>{{ $t('WHATSAPP_TEMPLATES.FORM.HEADEROPTIONAL.DESC') }}</h6>
              <p>
                {{ $t('WHATSAPP_TEMPLATES.FORM.HEADEROPTBODY.DESC') }}
              </p> -->
              <div
                v-for="index in headerCount"
                :key="index"
                class="flex p-3 gap-2"
              >
                <label>{{
                  $t(`WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE${index}.LABEL`)
                }}</label>
                <input
                  v-model="header_text[index - 1]"
                  type="text"
                  class="w-[95%]"
                />
              </div>
            </div>
          </div>
          <!-- FOR BODY BOX -->
          <label class="text-base font-bold pb-2">
            {{ $t('WHATSAPP_TEMPLATES.FORM.BODY.LABEL') }}
          </label>
          <!-- <P>
            {{ $t('WHATSAPP_TEMPLATES.FORM.DESC.LABEL') }}
          </P> -->
          <div class="mb-6">
            <woot-message-editor
              id="message-signature-input"
              v-model="body"
              class="message-editor"
              :is-format-mode="true"
              :min-height="4"
              @input="saveInputBoxData"
            />
            <p v-if="restrictionBody" class="required-asterisk">
              *No two vaiable at continous place
            </p>
            <div v-if="messageVisible" class="warning-message p-5">
              <li>
                <span class="text-xl">&#9888;</span>
                {{ $t('WHATSAPP_TEMPLATES.FORM.INFORMATION.LABEL') }}
                <router-link to="">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ROUTERTEXT.DESC') }}
                </router-link>
              </li>
            </div>
            <div
              class="float-right cursor-pointer"
              :disabled="clickCount >= 5"
              @mouseover="hovered = true"
              @mouseout="hovered = false"
              @click.prevent="addVariable"
            >
              {{ $t('WHATSAPP_TEMPLATES.FORM.ADDVARIABLE.DESC') }}
            </div>
          </div>
          <div class="body-content">
            <div v-if="showmessage" class="p-4">
              <!-- <h6>{{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODY.DESC') }}</h6>
              <p>
                {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODYCONTENT.DESC') }}
              </p>
              <div class="text-sm">
                {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODYCONTENT.DESC') }}
              </div> -->
              <div
                v-for="index in clickCount"
                :key="index"
                class="flex p-3 gap-2"
              >
                <label>
                  {{
                    $t(`WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE${index}.LABEL`)
                  }}
                </label>
                <input
                  v-model="body_text[index - 1]"
                  type="text"
                  class="w-[95%]"
                />
              </div>
            </div>
          </div>
          <!-- FOR FOOTER BOX -->
          <input type="checkbox" v-model="footerCheck"/>
          <label class="text-base font-bold pb-2">
            {{ $t('WHATSAPP_TEMPLATES.FORM.FOOTER.LABEL') }}
            <span class="text-xs pl-3">
              {{ $t('WHATSAPP_TEMPLATES.FORM.FOOTER.PLACEHOLDER') }}
            </span>
          </label>
          <P>
            {{ $t('WHATSAPP_TEMPLATES.FORM.INFO.LABEL') }}
          </P>
          <div class="mb-3" v-if="footerCheck">
            <input v-model="footer" type="text" @input="$v.footer.$touch" />
          </div>
          <!-- FOR BUTTON BOX -->
          <!-- <label class="text-base font-bold pb-2">
            {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTON.LABEL') }}
          </label>
          <p>
            {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTON_DESC.DESC') }}
          </p>
          <div class="button-card mb-4">
            <div class="p-3">
              <p class="text-base font-bold">
                W3 recommended adding the marketing opt-out button.
              </p>
              <p class="font-bold">
                {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTONBODY.DESC') }}
                <a>{{ $t('WHATSAPP_TEMPLATES.FORM.LEARNMORE.DESC') }} </a>
              </p>
            </div>
          </div> -->
          <!-- <div class="grid grid-cols-3">
            <select id="dropdown" v-model="buttonOption">
              <option value="none">
                {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE1.LABEL') }}
              </option>
              <option value="ActionButton">
                {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE2.LABEL') }}
              </option>
              <option value="QuickReply">
                {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE3.LABEL') }}
              </option>
            </select>
          </div>
          <div v-if="buttonOption === 'ActionButton'">
            <div class="grid grid-cols-3 gap-3">
              <div class="align-middle flex flex-row gap-4">
                <label for="checkWeb">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE4.LABEL') }}
                </label>
                <input
                  id="id="
                  v-model="enableWebsite"
                  type="checkbox"
                  name="checkWeb"
                  class="mb-0"
                />
              </div>
              <div class="w-[70%]">
                <label for="buttonText">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE5.LABEL') }}
                </label>
                <input
                  v-model="buttonText"
                  type="text"
                  :disabled="!enableWebsite"
                  @focus="isButtonFocused = true"
                  @blur="isButtonFocused = false"
                />
              </div>
              <div class="flex flex-row gap-3">
                <div class="w-[70%]">
                  <label for="websiteUrl">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE6.LABEL')
                    }}
                  </label>
                  <input
                    id="websiteUrl"
                    v-model="websiteUrl"
                    type="text"
                    :disabled="!enableWebsite"
                  />
                </div>
                <div>
                  <label for="dynamicUrl">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE7.LABEL')
                    }}
                  </label>
                  <input
                    id="dynamicUrl"
                    v-model="dynamicEnabled"
                    type="checkbox"
                    name="checkDynamic"
                    :disabled="!enableWebsite"
                    class="mb-0"
                    @click="addButtonVariable"
                  />
                </div>
              </div>
            </div>
            <div v-if="dynamicEnabled" class="dynamic-check mt-2">
              <div class="p-4">
                <p>
                  {{ $t('WHATSAPP_TEMPLATES.FORM.DYNAMIC.DESC') }}
                </p>
                <div class="text-base font-bold">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEURL.DESC') }}
                </div>
                <div class="flex p-3 gap-2">
                  <label>
                    {{ $t(`WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE1.LABEL`) }}
                  </label>
                  <input
                    v-model="dynamicValue"
                    type="text"
                    class="w-[95%]"
                    @input="updateDynamicValue"
                  />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3 gap-3">
              <div class="align-middle flex flex-row gap-4">
                <label for="checkCart">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE8.LABEL') }}
                </label>
                <input
                  id="id="
                  v-model="enableCart"
                  type="checkbox"
                  name="checkWeb"
                  class="mb-0"
                />
              </div>
              <div class="w-[70%]">
                <label for="buttonText">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE9.LABEL') }}
                </label>
                <input
                  v-model="buttonText1"
                  type="text"
                  :disabled="!enableCart"
                  @focus="isButtonFocusedforNumber = true"
                  @blur="isButtonFocusedforNumber = false"
                />
              </div>
              <div class="w-[70%]">
                <label for="phoneNumber">
                  {{
                    $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE10.LABEL')
                  }}
                </label>
                <input
                  id="phoneNumber"
                  v-model="phoneNumber"
                  type="text"
                  :disabled="!enableCart"
                  :class="{
                    'red-border': isButtonFocusedforNumber,
                  }"
                />
                <span
                  v-if="isInvalidPhoneNumber(phoneNumber)"
                  class="required-asterisk"
                  >{{ $t('WHATSAPP_TEMPLATES.FORM.MESSAGEERROR.DESC') }}</span
                >
              </div>
            </div>
          </div>
          <div v-if="buttonOption === 'QuickReply'">
            <div class="grid-cols-2 grid">
              <div class="mb-3">
                <label class="header" for="websiteUrl">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTONCART.DESC') }}
                </label>
                <input
                  id="replyButton"
                  v-model="replyButtonValue"
                  type="text"
                />
              </div>
            </div>
            <div class="">
              <div
                v-for="(card, index) in navigationCards"
                :key="index"
                class="mb-3"
              >
                <div class="grid-cols-2 grid">
                  <div>
                    <label>{{ card.label }}</label>
                    <input
                      :id="`card.id_${index}`"
                      v-model="card.value"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              class="text-gray-900 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ring-1 cursor-pointer text-woot-400"
              @click.prevent="addNavigationCard"
            >
              {{ $t('WHATSAPP_TEMPLATES.FORM.QUICKREPLYBUTTON.DESC') }}
            </button>
          </div> -->
        </div>
        <!-- <div class="flex items-center gap-1.5">
          <woot-submit-button
            :disabled="submitInProgress"
            :button-text="submitButtonText"
            :loading="submitInProgress"
          />
        </div> -->
      </div>
    </div>
  </form>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import { required } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import WhatsappSelectorLogic from './WhatsappSelectorLogic';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
    WootMessageEditor,
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const {
      header = '',
      file = '',
      fileName = '',
      body = '',
      footer = '',
      buttonText = '',
      buttonText1 = '',
      websiteUrl = '',
      phoneNumber = '',
      replyButton = '',
    } = formData;

    return {
      format: 'text',
      header,
      header_text: [],
      headerCount: 0,
      showheader: false,
      file,
      fileName,
      body,
      body_text: [],
      footer,
      buttonOption: 'none',
      buttonText,
      buttonText1,
      websiteUrl,
      phoneNumber,
      replyButton,
      enableWebsite: false,
      enableCart: false,
      navigationCards: [],
      replyButtonValue: '',
      previousInputBoxData: '',
      clickCount: 0,
      finalbody: '',
      previousVariableCount: 0,
      isButtonFocused: false,
      isButtonFocusedforNumber: false,
      characterLimitError: '',
      maxCharacterLimit: 60,
      wordCount: body.length,
      wordLimitError: '',
      messageVisible: false,
      showmessage: false,
      hovered: false,
      previousHeaderCount: 0,
      previousInputHeaderData: '',
      dynamicEnabled: false,
      dynamicValue: '',
      dynamicInput: '',
      hours: new Date()
        .getHours()
        .toString()
        .padStart(2, '0'),
      minutes: new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0'),
      restrictionHeader: false,
      restrictionBody: false,
      headerCheck: false,
      footerCheck: false
    };
  },
  computed:{
    ...mapGetters({
      templateLists: 'whatsappTemplates/getTemplateFlags',
      
    })
  },
  mounted(){
    console.log("This Template List",this.templateLists);
    const data=this.$store.getters['whatsappTemplates/getTemplateFlags'];
    console.log("Data is::::",data);
  },
  validations: {
    body: {
      required,
    },
    footer: {
      required,
    },
  },
  watch: {
    ...WhatsappSelectorLogic.watch,
  },
  methods: {
    ...WhatsappSelectorLogic.methods,
    emitData() {
      this.$emit('update', {
        headerCheck: this.headerCheck,
        format: this.format,
        header: this.header,
        header_text: this.header_text,
        body: this.body,
        body_text: this.body_text,
        footerCheck: this.footerCheck,
        footer: this.footer,
      });
    },
  },
  watch: {
    // Watch for changes in the data and emit
    headerCheck: 'emitData',
    format: 'emitData',
    header: 'emitData',
    header_text: {
      handler: 'emitData',
      deep: true,
    },
    body: 'emitData',
    body_text: {
      handler: 'emitData',
      deep: true,
    },
    footerCheck: 'emitData',
    footer: 'emitData',
    footerCheck(newValue){
      if(newValue==false){

      }
    }
  },
};
</script>

<style scoped>
input {
  @apply mb-0;
}
.upload-image-btn {
  position: relative;
  bottom: 10px;
}
.upload-image-btn input {
  position: absolute;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.red-border {
  border: 1px solid red;
}
.required-asterisk {
  color: red;
  margin-left: 5px;
  font-size: 11px;
}
.button-card {
  border: 1px solid #d1cece;
  border-left: 4px solid green;
  border-radius: 5px;
}
.warning-message {
  background-color: khaki;
}
.body-content,
.dynamic-check {
  background-color: #e9e9e9;
  margin-bottom: 5px;
  border-radius: 5px;
}
</style>
