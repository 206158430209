<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <empty-state
      :title="$t('WHATSAPP_TEMPLATES.FINISH.TITLE')"
      :message="$t('WHATSAPP_TEMPLATES.FINISH.MESSAGE')"
      :button-text="$t('WHATSAPP_TEMPLATES.FINISH.BUTTON_TEXT')"
    >
      <div class="w-full text-center">
        <router-link
          class="button success nice"
          :to="finishUrl"
        >
          {{ $t('WHATSAPP_TEMPLATES.FINISH.BUTTON_TEXT') }}
        </router-link>
      </div>
    </empty-state>
  </div>
</template>

<script>
import EmptyState from '../../../../components/widgets/EmptyState';

export default {
  components: {
    EmptyState,
  },
  computed: {
    finishUrl() {
      return `/app/accounts/${this.$route.params.accountId}/settings/inboxes/${this.$route.params.inboxId}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.website--code {
  @apply my-4 mx-auto max-w-[70%];
}
</style>
  