import SettingsContent from '../Wrapper';
import IndexHome from './Index';
import CreateTemplate from './create/Index';
import CreateWhatsapp from './create/CreateWhatsapp';
import AddContent from './create/AddContents';
import TakeaLook from './create/TakeaLook';
import FinishSetup from './FinishSetup';

import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/whatsapp_template'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'whatsapp_template_list';
        return {
          headerTitle: 'WHATSAPP_TEMPLATES.HEADER',
          headerButtonText: 'WHATSAPP_TEMPLATES.HEADER_BTN_TXT',
          icon: 'whatsapp',
          newButtonRoutes: ['whatsapp_template_new'],
          // showNewButton: true,
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'whatsapp_templates',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'whatsapp_template_list',
          component: IndexHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateTemplate,
          children: [
            {
              path: '',
              name: 'whatsapp_template_new',
              component: CreateWhatsapp,
              roles: ['administrator'],
            },
            {
              path: 'add_contents',
              name: 'whatsapp_template_add_contents',
              roles: ['administrator'],
              component: AddContent,
            },
            {
              path: 'take_a_look',
              name: 'whatsapp_template_take_a_look',
              roles: ['administrator'],
              component: TakeaLook,
            },
            {
              path: 'finish',
              name: 'whatsapp_template_finish',
              roles: ['administrator'],
              component: FinishSetup,
            },
          ],
        },
      ],
    },
  ],
};
