import { required, minLength } from 'vuelidate/lib/validators';

export const validLabelCharacters = (str = '') => !!str && !str.includes(' ');

export default {
  title: {
    required,
    minLength: minLength(2),
    validLabelCharacters,
  },
  description: {},
  triggeredBefore: {
    required
  },
  macro: {
    required
  },
  // fields: {
  //   $each: {
  //     triggeredBefore: {
  //       required,
  //     },
  //     macro: {
  //       required,
  //     },
  //   }
  // },
  color: {
    required,
  },
  showOnSidebar: {},
};
