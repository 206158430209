<template>
  <div v-if="isVisible" class="modal-backdrop">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <p>
        One Automation is already Exist. Do you want to add more automation?
      </p>
      <div class="modal-footer">
        <div class="confirm-yes" @click="confirmYes">Yes</div>
        <div class="confirm-no" @click="confirmNo">No</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props:{
    isVisible:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    };
  },
  methods: {
    onBackdropClick() {
      this.close();
    },
    close() {
      this.$emit('close');
    },
    confirmYes() {
      // Handle the "Yes" confirmation
      this.$emit('add-automation');
      this.close();
    },
    confirmNo() {
      // Handle the "No" confirmation
      console.log("Title");
      this.$emit('remove-automation');
      this.close();
    },
  },
};
</script>
  
  <style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.close {
  cursor: pointer;
  font-size: 24px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-yes,
.confirm-no {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  text-align: center;
}

.confirm-no {
  background-color: #dc3545;
}
</style>
  