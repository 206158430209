<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="$t('MERGE_CONTACTS.REMINDER_TITLE')"
      :header-content="$t('MERGE_CONTACTS.REMINDER_DESCRIPTION')"
    />
    <div class="remainder-table">
      <div class="remainder-modal">
        <div class="remainder-input date-time">
          <div class="date-time-input">
            <label>
              {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
              <span class="text-red-500">*</span>
              <woot-date-time-picker
                :value="scheduledAt"
                class="date-time-picker-modal"
                :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
                @change="onChange"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="footer flex flex-col items-start pt-8 px-8 pb-8 remainder-footer"
    >
      <div>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('MERGE_CONTACTS.FORM.CANCEL') }}
        </woot-button>
      </div>
      <div>
        <woot-button type="submit" :is-disabled=sending_data
        :is-loading=sending_data @click.prevent="setRemainder">
          {{ $t('MERGE_CONTACTS.FORM.SUBMIT_REMAINDER') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>
  
<script>
import alertMixin from 'shared/mixins/alertMixin';
import MergeContact from 'dashboard/modules/contact/components/MergeContact';
import ContactAPI from 'dashboard/api/contacts';
import { mapGetters } from 'vuex';
import { CONTACTS_EVENTS } from '../../helper/AnalyticsHelper/events';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';

export default {
  components: { MergeContact, WootDateTimePicker },
  mixins: [alertMixin],
  props: {
    primaryContact: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    datePart: String,
    timeWithoutZ: String,
  },
  data() {
    return {
      isSearching: false,
      searchResults: [],
      date: '',
      time: '',
      scheduledAt: null,
      sending_data: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      records: 'labels/getLabels',
      conversationId: 'getSelectedChat',
      uiFlags: 'labels/getUIFlags'
    }),
    minDate() {
      // Get the current date
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    minTime() {
      // Get the current time
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();

      // If selected date is today, disable previous times
      if (this.date === this.minDate) {
        return `${currentHours.toString().padStart(2, '0')}:${currentMinutes
          .toString()
          .padStart(2, '0')}`;
      } else {
        return '00:00'; // Allow any time if not today
      }
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
    if (this.datePart && this.timeWithoutZ) {
      const dateTimeString = `${this.datePart} ${this.timeWithoutZ}`;
      // Convert to a Date object if both datePart and timeWithoutZ are provided
      this.scheduledAt = new Date(dateTimeString);
    } else {
      // Use the current date and time if either datePart or timeWithoutZ is empty
      this.scheduledAt = new Date();
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async onContactSearch(query) {
      this.isSearching = true;
      this.searchResults = [];

      try {
        const {
          data: { payload },
        } = await ContactAPI.search(query);
        this.searchResults = payload.filter(
          contact => contact.id !== this.primaryContact.id
        );
      } catch (error) {
        this.showAlert(this.$t('MERGE_CONTACTS.SEARCH.ERROR_MESSAGE'));
      } finally {
        this.isSearching = false;
      }
    },
    async onMergeContacts(childContactId) {
      this.$track(CONTACTS_EVENTS.MERGED_CONTACTS);
      try {
        await this.$store.dispatch('contacts/merge', {
          childId: childContactId,
          parentId: this.primaryContact.id,
        });
        this.showAlert(this.$t('MERGE_CONTACTS.FORM.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('MERGE_CONTACTS.FORM.ERROR_MESSAGE'));
      }
    },
    async setRemainder() {
      console.log("Schedule At:::::",this.scheduledAt);
      if (this.scheduledAt != null) {
        try {
          this.sending_data=true;
          const dateObj = new Date(this.scheduledAt);
          const optionsDate = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          };
          const optionsTime = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          };

          const formattedDate = dateObj.toLocaleDateString(
            'en-US',
            optionsDate
          );
          const formattedTime = dateObj
            .toLocaleTimeString('en-US', optionsTime)
            .replace(' ', '')
            .toUpperCase();
          const message = {
            content: `Your Demo Schedule at ${formattedDate} ${formattedTime}`,
            private: true,
          };

          let dateString = this.scheduledAt.toString();

          // Parse the date string and extract the GMT offset
          let dateParts = dateString.split(' ');
          let timeParts = dateParts[4].split(':');
          let hour = parseInt(timeParts[0]);
          let minute = parseInt(timeParts[1]);
          let second = parseInt(timeParts[2]);

          let dayName = dateParts[0];
          let monthName = dateParts[1];
          let day = dateParts[2];
          let year = dateParts[3];
          let gmtOffset = dateParts[5].substring(3, 8);

          // Convert the hour to 12-hour format
          let ampm = hour >= 12 ? 'PM' : 'AM';
          hour = hour % 12;
          hour = hour ? hour : 12; // the hour '0' should be '12'

          // Format the final date string
          let formattedDateString = `${dayName}, ${monthName} ${day}, ${year}, ${hour}:${String(
            minute
          ).padStart(2, '0')}:${second} ${ampm} ${gmtOffset}`;
          const calendar_timings = formattedDateString;
          
          const data = {
            calendar_timings: calendar_timings,
            messages: message,
          };
          const response = await this.$store.dispatch('contacts/demo_time', {
            conversationId: this.conversationId.messages[0].conversation_id,
            data: data,
            });

            if (response.success == true) {
            const conversationId =
              this.conversationId.messages[0].conversation_id;
            const message = response.message;
            const demo_time = this.scheduledAt;
            this.$emit('success-message', {
              message,
              conversationId,
              formattedDate,
              formattedTime,
              demo_time,
              });
            this.onClose();
            this.sending_data=false;
          }
        } catch (err) {
          this.onClose();
          console.error('Error while Submitting the Data:-', err);
        }
      } else {
        this.showAlert('Schedule Demo Time is Mandatory Field');
      }
    },
    formatDate(dateStirng) {
      const date = new Date(dateStirng);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatTime(timeString) {
      const cleanedTimeString = timeString.replace(/ /g, '');
      const time = cleanedTimeString.slice(0, -2);
      const modifier = cleanedTimeString.slice(-2);
      let [hours, minutes] = time.split(':');

      if (modifier === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
      }
      if (modifier === 'AM' && hours === '12') {
        hours = '00';
      }
      return `${hours.toString().padStart(2, '0')}:${minutes}`;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getCurrentTime() {
      const date = new Date();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    onChange(value) {
      this.scheduledAt = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.remainder-table {
  margin: 10px 25px;
}

.remainder-modal {
  display: flex;
}

.remainder-input {
  width: 100%;
}
.remainder-text {
  width: 20%;
  font-size: 14px;
  font-weight: 500;
}
.remainder-text-select {
  padding: 5% 0px;
}
.input-textarea textarea {
  resize: none;
}
.date-time {
  display: flex;
  justify-content: space-between;
}
.date,
.time {
  margin: 0px 2%;
}
.remainder-time {
  display: flex;
  justify-content: inherit;
  width: 35%;
}
.date-time-input {
  width: 100%;
}
.footer {
  @apply mt-6 flex justify-end;
}
.remainder-time select {
  width: 66%;
}
.remainder-footer {
  flex-direction: inherit;
}
.labels-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.automation-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.label-automation {
  padding-top: 10px;
  justify-content: space-between;
}
.labels-text {
  width: 30%;
}
.label-input {
  width: 50%;
}
.automation-text {
  width: 55%;
}
.automation-input {
  width: 50%;
}
.overlay {
  position: fixed;
  margin: auto;
  left: 50vw;
  width: 250px;
}
.success-popup {
  background-color: #44ce4b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.date-time-picker-modal {
  width: 90%;
  padding-top: 2%;
}
</style>
