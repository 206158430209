<template>
  <div
    class="wizard-body w-[100%] flex-shrink-0 flex-grow-0 max-w-[100%] overflow-y-auto custom-height"
  >
    <div class="w-full">
      <page-header :header-content="$t('WHATSAPP_TEMPLATES.ADD.DESC')" />
    </div>

    <!-- <div class="w-full">
      <whatsapp-selector
        :on-submit="takeLook"
        :submit-in-progress="false"
        :submit-button-text="$t('WHATSAPP_TEMPLATES.ADD.BUTTON_TEXT')"
      />
    </div> -->
    <div class="w-full">
      <whatsapp-form @update="updateFormData" />
    </div>
    <div class="w-full">
      <whatsapp-selector @update="updateSelectorData" />
    </div>

    <!-- <div class="w-full">
      <whatsapp-form
        :on-submit="createWhatsapp"
        :submit-in-progress="false"
        :submit-button-text="$t('WHATSAPP_TEMPLATES.FORM.SUBMIT_CREATE')"
      />
    </div> -->

 

    <!-- <div>
      <wizard/>
    </div> -->
    <div class="flex items-center gap-1.5">
      <!-- <woot-submit-button
        :disabled="submitInProgress"
        :button-text="submitButtonText"
        :loading="submitInProgress" 
      /> -->
      <woot-submit-button
        :button-text="submitButtonText"
        :disabled="uiFlags.isCreating||disablebutton"
        :is-loading="uiFlags.isCreating"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import WhatsappForm from '../WhatsappForm.vue';
import WhatsappSelector from '../WhatsappSelector';
import { mapGetters } from 'vuex';
// import Wizard from '../../../../../components/ui/Wizard.vue';

export default {
  components: {
    PageHeader,
    WhatsappSelector,
    // Wizard
    WhatsappForm,
  },
  mixins: [alertMixin],

  data() {
    return {
      enabledFeatures: {},
      submitButtonText: 'Save',
      formData: {
        selectorData: {},
        formData: {}, // Object to hold form data from WhatsappForm
      },
      disablebutton: true
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      uiFlags: 'whatsappTemplates/getUIFlags',
    }),
  },
  props: {
    // inbox: {
    //   type: Object,
    //   required: true
    // },
    // currentAccountId: {
    //   type: String,
    //   required: true,
    // },
  },
  mounted() {
    this.template_data = this.$route.params.template_data;
  },
  
  methods: {
    takeLook(content_data) {
      router.replace({
        name: 'whatsapp_template_take_a_look',
        params: {
          page: 'take_a_look',
          content_data: content_data,
          templateData: this.template_data,
        },
      });
    },
    updateFormData(data) {
      // Handle data from WhatsappForm
      this.formData.formData = data;
      
      console.log('Form Data:', data);
    },
    updateSelectorData(data) {
      // Handle data from WhatsappSelector
      this.formData.selectorData = data;
      if(this.formData.selectorData&&this.formData.selectorData.body.length>0){
        console.log("My Data:::")
        this.disablebutton=false;
      }
      else{
        console.log("Else My Data:::")
        this.disablebutton=true;
      }
      console.log('Selector Data:', data);
    },
    async handleSubmit() {
      // Handle the final form submission
      try{

        const token = this.currentUser.access_token;
        console.log('Final Data', this.formData);
        const finalData = {
          // ...this.formData, // Add formData fields
          name: this.formData?.formData?.name,
          category: this.formData?.formData?.categoryOption,
          language: this.formData?.formData?.locale,
          header: this.formData?.selectorData.headerCheck
            ? this.formData?.selectorData?.header
            : '',
          sample_header: this.formData?.selectorData.headerCheck
            ? this.formData?.selectorData?.header_text
            : [],
          body: this.formData?.selectorData?.body,
          sample_body: this.formData?.selectorData?.body_text,
          footer: this.formData?.selectorData.footerCheck
            ? this.formData?.selectorData?.footer
            : '',
          inbox: this.$route.params.inboxId,
        };
        console.log('Send Data:', finalData);
        // Perform save operation or API call here
        // Example:
        // this.saveToDatabase(this.formData);
        const response = await this.$store.dispatch('whatsappTemplates/create', {
          accountId: this.currentAccountId,
          data: { ...finalData, token: token, user: this.currentUser },
        });
        console.log('Response', response);
        if (response?.data?.error?.error_user_msg){
          this.showAlert(`${response?.data?.error?.error_user_msg}`);
            // router.replace({
            //   name: 'whatsapp_template_list'
            // });
        }else if (response.status == 200 && response.data.status) {
          this.showAlert('Template created successfully');
          router.replace({
            name: 'whatsapp_template_list'
          });
        }
      }catch(err){
        console.error("Error While Creating the Data",err);
        this.showAlert('Error Occur During Create',err);
      }
    },
  },
};
</script>
<style scoped>
.custom-height {
  height: 93% !important;
}
</style>