<template>
  <woot-modal :show.sync="show" :on-close="onCancel" modal-type="right-aligned">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('CREATE_CONTACT.TITLE')"
        :header-content="$t('CREATE_CONTACT.DESC')"
      />
      <contact-form
        :in-progress="uiFlags.isCreating"
        :on-submit="onSubmit"
        :extra-value="extraValue"
        @success="onSuccess"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactForm from './ContactForm';

export default {
  components: {
    ContactForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return{
      extraValue: false,
    }
  },

  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },

  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
   async onSubmit(contactItem) {
    try {
      const current_contact = await this.$store.dispatch('contacts/create', contactItem);
      if(current_contact.success == false){
        this.extraValue = true;
        const existing_contact_id = current_contact.contact[0].id;
        const account_id = current_contact.contact[0].account_id;
        this.$router.push(`/app/accounts/${account_id}/contacts/${existing_contact_id}`);
      }else{
        this.$router.push(`/app/accounts/${this.$route.params.accountId}/contacts/${current_contact.id}`);
      }
    } catch (error) {
      console.error("Error creating contact:", error);
    }
  },
  },
};
</script>
